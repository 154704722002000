import { PeopleLaughingImage } from '../assets/images';
import { Copyright, ResetPasswordForm } from '../components';
import { Helmet } from "react-helmet";
import { useAuth, usePageTracking } from '../hooks';
import { useNavigate } from 'react-router-dom';
import Routes from '../../config/Routes';
import { useEffect } from 'react';

const ResetPasswordPage = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  usePageTracking();

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(Routes.HOME_PATH, { replace: true });
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="flex flex-wrap h-screen">
      <Helmet>
        <title>Prestaseguro | Olvide mi contraseña</title>
      </Helmet>
      <div className="w-full xl:w-1/2 flex flex-col justify-center items-center md:p-20">
        <ResetPasswordForm className="w-full h-full" />

        <Copyright className="text-slate-300 text-sm hidden md:flex flex-row absolute bottom-0 left-0 pb-5 px-10 justify-end w-1/2 space-x-10" />
      </div>

      <img className="hidden xl:flex w-full xl:w-1/2 h-24  md:h-screen object-cover object-right" src={PeopleLaughingImage} alt="login" />
    </div>
  )
}

export default ResetPasswordPage;