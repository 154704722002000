import { PageContainer, UpdatePersonalInformationForm } from "../components";
import { usePageTracking, useUpdatePersonalInformationForm } from "../hooks";
import { NavLink } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Routes from "../../config/Routes";

const UpdatePersonalInformationPage = () => {
  usePageTracking();

  const handler = useUpdatePersonalInformationForm();

  return (
    <PageContainer className="flex-col">
      <h1 className="text-4xl font-bold">Actualiza tu información</h1>
      
      <div className="mt-8 bg-white rounded-lg shadow-lg flex-col flex">
        <NavLink
          className="flex justify-start pl-10 pt-10 items-center font-bold opacity-65"
          to={handler.updateAttemptComesFromApplyingForNewLoan() ? Routes.HOME_PATH : Routes.ACCOUNT_PATH}
        >
          <ArrowBackIosIcon style={{ fontSize: '1rem' }} className="text-gray-500 hover:text-gray-400"/>
          Volver
        </NavLink>
        <UpdatePersonalInformationForm handler={handler} />
      </div>
    </PageContainer>
  )
}

export default UpdatePersonalInformationPage;