//import { useState } from "react";

import { useState } from "react";
import { InputValidator, Number, Selector } from "../../../infrastructure/inputs";
import { Constants, Environment } from "../../../utils";
import { useSelector } from "react-redux";
import { selectConfig } from "../../../infrastructure/redux/selectors";
import { Config } from "../../../domain/models";
import { useConfig } from "..";

const useNewLoanBankingDataForm = () => {
  const config: Config | null | undefined = useSelector(selectConfig);
  const { bankAccount } = useConfig();
  const [bankAccountType, setBankAccountType] = useState(Selector.dirtyWithOptions(bankAccount?.type_cd.toString() ?? "", Object.values(Constants.BANK_ACCOUNT_TYPES)));
  const [bankCode, setBankCode] = useState(Selector.dirtyWithOptions(bankAccount?.bank?.code ?? "", config?.banks.map(bank => bank.code) ?? []));
  const [bankAccountNumber, setBankAccountNumber] = useState(Number.dirty(bankAccount?.number ?? ""));
  const [submitted, setSubmitted] = useState(false);

  const submitForm = () => {
    setSubmitted(true);
    if (!validForm()) return false;

    return true;
  };

  const developmentAutoFill = () => {
    if (Environment.env !== 'development') return;

    setBankAccountType(Selector.dirtyWithOptions(Constants.BANK_ACCOUNT_TYPES['Ahorros'].toString(), Object.values(Constants.BANK_ACCOUNT_TYPES)));
    setBankCode(Selector.dirtyWithOptions('BCL', config?.banks.map(bank => bank.code) ?? []));
    setBankAccountNumber(Number.dirty('1233890417'));
  }

  const data = () => {
    return {
      bankAccountType: bankAccountType.getNumber(),
      bankCode: bankCode.value, 
      bankAccountNumber: bankAccountNumber.value
    }
  }

  const validForm = (): boolean => {
    return InputValidator.valid([
      bankAccountType,
      bankCode,
      bankAccountNumber
    ]);
  }

  return {
    bankAccountType, setBankAccountType,
    bankCode, setBankCode,
    bankAccountNumber, setBankAccountNumber,
    submitted, submitForm,
    data, developmentAutoFill,
    config
  }
};

export default useNewLoanBankingDataForm;