interface IPaymentTransaction {
  id: string;
  createdAt: Date;
  succeeded: boolean;
  amount: number;
}

export default class PaymentTransaction {
  id: string;
  createdAt: Date;
  succeeded: boolean;
  amount: number;

  constructor({
    id, createdAt, succeeded,
    amount
  }: IPaymentTransaction) {
    this.id = id;
    this.createdAt = createdAt;
    this.succeeded = succeeded;
    this.amount = amount;
  }
}