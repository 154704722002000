import { NavLink } from 'react-router-dom';
import { LogoWithText } from '../assets/images';
import { useResetPasswordForm } from '../hooks';
import { Button, Input } from './';
import { Email, InputValidator } from '../../infrastructure/inputs';
import Routes from '../../config/Routes';

interface ResetPasswordFormProps {
  className?: string;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ className = '' }) => {
  const { loading, email, setEmail, submitted, submitForm } = useResetPasswordForm();

  return (
    <form className={`${className} flex flex-wrap flex-col bg-white px-10 rounded-lg shadow-lg py-10 sm:py-0 sm:justify-center items-center`} onSubmit={(event) => { event.preventDefault(); submitForm(); }}>
      <img className="h-auto w-40" src={LogoWithText} alt="login" />
      <h1 className="text-3xl font-bold mt-5">¿Olvidaste tu contraseña?</h1>
      <h4 className="text-md mt-5 text-center px-10">Te enviaremos un correo electrónico con instrucciones para restablecerla.</h4>

      <div className="flex flex-col flex-wrap space-y-5 w-full mt-5">
        <Input
          placeholder="Correo electrónico"
          type="email"
          value={email.value}
          errorMessage={submitted ? email.errorMessage() : null}
          onChange={(event) => { setEmail(Email.dirty(event.target.value)); }}
        />
      </div>

      <Button loading={loading} disabled={InputValidator.anyEmpty([email])} className="mt-8 w-full" text="Recuperar contraseña" type='submit'/>

      <div className="flex justify-center w-full text-sm mt-3">
        ¿Ya tienes cuenta? <NavLink to={Routes.LOGIN_PATH} className="ml-2 text-sky-500 hover:text-sky-700 font-bold">Inicia sesión</NavLink>
      </div>
    </form>
  )
}

export default ResetPasswordForm;