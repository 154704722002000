import { useDispatch } from 'react-redux';
import { TokenRepository } from '../../domain/repositories';
import { configSlice } from '../../infrastructure/redux/slices';
import LocalStorageTokenRepository from '../../infrastructure/repositories/LocalStorageTokenRepository';

const useAuth = () => {
  const dispatch = useDispatch();

  const tokenRepository: TokenRepository = LocalStorageTokenRepository.getInstance();

  const saveToken = (token: string) => {
    tokenRepository.saveToken(token);
  }

  const removeToken = () => {
    tokenRepository.removeToken();
  }

  const getToken = (): string | null => {
    return tokenRepository.getToken();
  }

  const isAuthenticated = (): boolean => {
    return getToken() !== null;
  }

  const logout = () => {
    removeToken();
    dispatch(configSlice.actions.resetConfig());
  }

  return {
    saveToken,
    isAuthenticated,
    getToken,
    logout
  };
};

export default useAuth;