import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Config, User } from "../../../domain/models";

interface ConfigState {
  config?: Config | null;
}
const initialState: ConfigState = {
  config: null
};

export default createSlice({
  name: 'configSlice',
  initialState,
  reducers: {
    saveConfig: (state, action: PayloadAction<Config>) => {
      state.config = action.payload;
    },
    resetConfig: (state) => {
      state.config = initialState.config;
    },
    setActiveLoan: (state) => {
      const user: User | undefined = state.config?.user;
      if(user) {
        user.hasActiveCredit = true;

        if (state.config) {
          state.config.user = user;
        }
      }
    },
    updateUser: (state, action: PayloadAction<User>) => {
      if(state.config) {
        state.config!.user = action.payload;
      }
    }
  }
})
