import { useState } from 'react';
import { LoansRepository } from '../../domain/repositories';
import { RestLoansRepository } from '../../infrastructure/repositories';
import { Loan } from '../../domain/models';
import { Alert } from '../../utils';
import useConfig from './useConfig';

const useLoanDetail = () => {
  const [loading, setLoading] = useState(false);
  const [loan, setLoan] = useState<Loan | undefined>(undefined);
  const { user } = useConfig();

  const loansRepository: LoansRepository = RestLoansRepository.getInstance();

  const loadLoan = async (loanId: string) => {
    setLoading(true);
    const [retrievedLoan, errorMessage] = await loansRepository.loanById(user!.id, loanId);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setLoan(retrievedLoan);
    }
    setLoading(false);
  };

  return {
    loan,
    loading,
    loadLoan
  };
};

export default useLoanDetail;