import { Outlet } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { Config } from '../';
import Routes from '../../../config/Routes';

const PrivateRoute = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated()) {
    return <Navigate to={Routes.LOGIN_PATH} replace />;
  }

  return (
    <Config>
      <Outlet />
    </Config>
  );
};

export default PrivateRoute;