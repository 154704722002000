import { User, Bank, CreditPurpose, BankAccount } from "./";

interface IConfig {
  user: User;
  bankAccount?: BankAccount;
  purposes: CreditPurpose[];
  banks: Bank[];
  maxCreditAmount: number;
  minCreditAmount: number;
  maxCreditTerm: number;
  minCreditTerm: number;
  onlinePaymentFeeRate: number;
}

export default class Config {
  user: User;
  bankAccount?: BankAccount;
  purposes: CreditPurpose[];
  banks: Bank[];
  maxCreditAmount: number;
  minCreditAmount: number;
  maxCreditTerm: number;
  minCreditTerm: number;
  onlinePaymentFeeRate: number;
  
  constructor({ user, bankAccount, purposes, banks, maxCreditAmount, minCreditAmount, maxCreditTerm, minCreditTerm, onlinePaymentFeeRate }: IConfig) {
    this.user = user;
    this.bankAccount = bankAccount;
    this.purposes = purposes;
    this.banks = banks;
    this.maxCreditAmount = maxCreditAmount;
    this.minCreditAmount = minCreditAmount;
    this.maxCreditTerm = maxCreditTerm;
    this.minCreditTerm = minCreditTerm;
    this.onlinePaymentFeeRate = onlinePaymentFeeRate;
  }
}