import { NavLink } from 'react-router-dom';
import { LogoWithText } from '../assets/images';
import { useLoginForm } from '../hooks';
import { Button, Input } from './';
import { Email, InputValidator, Password } from '../../infrastructure/inputs';
import Routes from '../../config/Routes';

interface LoginFormProps {
  className?: string;
}

const LoginForm: React.FC<LoginFormProps> = ({ className = '' }) => {
  const { loading, email, password, setEmail, setPassword, submitted, submitForm } = useLoginForm();

  return (
    <form className={`${className} flex flex-wrap flex-col bg-white px-10 rounded-lg shadow-lg py-10 sm:py-0 sm:justify-center items-center`} onSubmit={(event) => { event.preventDefault(); submitForm(); }}>
      <img className="h-auto w-40" src={LogoWithText} alt="login" />
      <h1 className="text-3xl font-bold mt-5">Inicia sesión</h1>

      <div className="flex flex-col flex-wrap space-y-5 w-full mt-5">
        <Input
          placeholder="Correo electrónico"
          type="email"
          value={email.value}
          errorMessage={submitted ? email.errorMessage() : null}
          onChange={(event) => { setEmail(Email.dirty(event.target.value)); }}
        />
        <Input
          placeholder="Contraseña"
          type="password"
          value={password.value}
          errorMessage={submitted ? password.errorMessage() : null}
          onChange={(event) => { setPassword(Password.dirty(event.target.value)) }}
        />
      </div>

      <div className="flex justify-start w-full mt-3">
        <NavLink to={Routes.RESET_PASSWORD_PATH} className="text-sky-500 hover:text-sky-700 font-bold text-sm">¿Olvidaste tu contraseña?</NavLink>
      </div>

      <Button loading={loading} disabled={InputValidator.anyEmpty([email, password])} className="mt-8 w-full" text="Iniciar sesión" type='submit'/>

      <div className="flex justify-start w-full text-sm mt-3 text-center">
        ¿No tienes cuenta? <NavLink to={Routes.SIGNUP_PATH} className="ml-2 text-sky-500 hover:text-sky-700 font-bold text-center">Crear una cuenta</NavLink>
      </div>
    </form>
  )
}

export default LoginForm;