import { PageContainer } from "../components";
import { usePageTracking } from "../hooks";

const PaymentMethodsPage = () => {
  usePageTracking();

  return (
    <PageContainer pageTitle="Métodos de pago" className="flex-col">
      <h1 className="text-4xl font-bold">Métodos de pago</h1>
      <div className="text-gray-500 flex flex-wrap flex-col bg-white mt-5 rounded-lg shadow-sm border border-gray-200 py-10 px-6 md:p-20 space-y-5">
        <p>Para pagar tu crédito, solo necesitas acceder a nuestra página web y dirigirte a la sección de Créditos. Una vez allí, selecciona el botón de PAGAR correspondiente al crédito que deseas cancelar. Este enlace te dirigirá a EPAYCO, donde podrás realizar el pago de forma fácil, rápida y segura, utilizando diversos métodos de pago. Es importante tener en cuenta que los pagos en línea están sujetos a un recargo del 4%. Si prefieres evitar este cargo adicional, te recomendamos hacer una transferencia bancaria.</p>
        <p>Cuando desees realizar el pago del préstamo a través de transferencia bancaria, puedes hacerlo a las siguientes cuentas:</p>

        <div className="space-y-5 overflow-x-auto">
          <table className="border-collapse border border-slate-400">
            <thead>
              <tr>
                <th className="text-xs border border-slate-300 p-2">Nombre titular</th>
                <th className="text-xs border border-slate-300 p-2">NIT titular</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-xs border border-slate-300 p-2">INVERSIONES Y PRÉSTAMOS S.A.S.</td>
                <td className="text-xs border border-slate-300 p-2">901.863.221-2</td>
              </tr>
            </tbody>
          </table>

          <table className="w-full border-collapse border border-slate-400">
            <thead>
              <tr>
                <th className="text-xs border border-slate-300 p-0.5 lg:p-2">Banco</th>
                <th className="text-xs border border-slate-300 p-0.5 lg:p-2">Tipo de Cuenta</th>
                <th className="text-xs border border-slate-300 p-0.5 lg:p-2">Numero de Cuenta</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-xs border border-slate-300 p-0.5 lg:p-2">Bancolombia</td>
                <td className="text-xs border border-slate-300 p-0.5 lg:p-2">Ahorros</td>
                <td className="text-xs border border-slate-300 p-0.5 lg:p-2 text-sky-400">171.000.303.73</td>
              </tr>
              {/*<tr>
                <td className="text-xs border border-slate-300 p-0.5 lg:p-2">Bancoomeva</td>
                <td className="text-xs border border-slate-300 p-0.5 lg:p-2">Ahorros</td>
                <td className="text-xs border border-slate-300 p-0.5 lg:p-2 text-sky-400">0508.0283.1301</td>
  </tr>*/}
            </tbody>
          </table>
        </div>
        
        <p>Una vez completada la transferencia, por favor envíanos el comprobante a través de correo electrónico a: <a href="mailto:prestamos@prestaseguro.com?subject=Comprobante%20transaccion%20" className="font-bold text-sky-400">prestamos@prestaseguro.com</a>, o contáctanos vía WhatsApp al: <a href="https://wa.me/3022364654" target="_blank" rel='noreferrer' className="font-bold text-sky-400">3022364654</a>. De esta manera, podremos actualizar el estado de tu crédito.</p>
      </div>
    </PageContainer>
  )
}

export default PaymentMethodsPage;