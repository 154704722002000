import { useState } from 'react';
import { Email, InputValidator } from '../../infrastructure/inputs';
import { UsersRepository } from '../../domain/repositories';
import { RestUsersRepository } from '../../infrastructure/repositories';
import { useNavigate } from 'react-router-dom';
import Routes from '../../config/Routes';
import { Alert } from '../../utils';

const useLoginForm = () => {
  const [email, setEmail] = useState(Email.dirty(''));
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const usersRepository: UsersRepository = RestUsersRepository.getInstance();

  const submitForm = async () => {
    setSubmitted(true);
    if (!validForm()) return;

    setLoading(true);
    const [, errorMessage]: [boolean, string?] = await usersRepository.resetPassword(email.value);
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      Alert.showSuccess('El correo fue enviado correctamente.')
      navigate(Routes.LOGIN_PATH, { replace: true });
    }
  };

  const validForm = (): boolean => {
    return InputValidator.valid([email]);
  }

  return {
    loading,
    email,
    setEmail,
    submitted,
    submitForm
  };
};

export default useLoginForm;