import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

interface ActionCardProps {
  className?: string;
  onClick?: () => void;
  name: string;
  icon: string;
  disabled?: boolean;
}

const ActionCard: React.FC<ActionCardProps> = ({ className, onClick, name, icon, disabled = false }) => {
  const opacity = disabled ? 'opacity-60' : 'opacity-100 hover:opacity-80';

  return (
    <button disabled={disabled} className={`${className} ${opacity} bg-white rounded-lg p-5 flex flex-row shadow-sm justify-center items-center space-x-5 border border-gray-200`} onClick={onClick}>
      <img className="h-10 w-auto" src={icon} alt={name} />
      <span className="text-md font-semibold">{name}</span>
      <ArrowCircleRightOutlinedIcon style={{ fontSize: '1.5rem' }} className="text-gray-500" />
    </button>
  )
}
export default ActionCard;