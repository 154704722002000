import Lottie from 'react-lottie';
import { NotFoundAnimation } from '../assets/animation';
import { usePageTracking } from '../hooks';

const NotFoundPage = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: NotFoundAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  usePageTracking();

  return (
    <div className="flex flex-col flex-wrap w-screen h-screen justify-center items-center space-y-10">
      <Lottie options={defaultOptions} height={150} width={250} />
      <h1 className="text-lg md:text-2xl font-bold">¡Ups! Página no encontrada</h1>
    </div>
  )
}

export default NotFoundPage;