import { Environment } from "../../../utils";

interface CopyrightProps {
  className?: string;
}

const Copyright: React.FC<CopyrightProps> = ({ className = '' }) => {
  return (
    <div className={className}>
      <div>Prestaseguro &copy; 2024</div>
      <a href={Environment.termsUrl} target="_blank" rel="noreferrer">Términos</a>
    </div>
  )
}

export default Copyright;