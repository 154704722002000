import { useState } from 'react';
import { Email, InputValidator, Password } from '../../infrastructure/inputs';
import { UsersRepository } from '../../domain/repositories';
import { RestUsersRepository } from '../../infrastructure/repositories';
import { Session } from '../../domain/models';
import { useNavigate } from 'react-router-dom';
import Routes from '../../config/Routes';
import { useAnalyticEvents, useAuth } from "./";
import { Alert, AnalyticEvents } from '../../utils';

const useLoginForm = () => {
  const [email, setEmail] = useState(Email.dirty(''));
  const [password, setPassword] = useState(Password.dirty(''));
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const { saveToken } = useAuth();
  const { logEvent } = useAnalyticEvents();

  const usersRepository: UsersRepository = RestUsersRepository.getInstance();

  const submitForm = async () => {
    setSubmitted(true);
    if(!validForm()) return;

    setLoading(true);
    const [session, errorMessage]: [Session?, string?] = await usersRepository.login(email.value, password.value);
    setLoading(false);

    if(errorMessage) {
      Alert.showError(errorMessage);
    } else {
      saveToken(session!.token);
      logEvent(AnalyticEvents.LOGIN_EVENT);
      navigate(Routes.HOME_PATH, { replace: true });
    }
  };

  const validForm = (): boolean => {
    return InputValidator.valid([email, password]);
  }

  return {
    loading,
    email,
    setEmail,
    password,
    setPassword,
    submitted,
    submitForm
  };
};

export default useLoginForm;