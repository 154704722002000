import { useState } from 'react';
import { LoansRepository } from '../../domain/repositories';
import { RestLoansRepository } from '../../infrastructure/repositories';
import { Loan } from '../../domain/models';
import { Alert } from '../../utils';
import useConfig from './useConfig';

const useLoans = () => {
  const [loading, setLoading] = useState(false);  
  const [loans, setLoans] = useState<Loan[] | undefined>(undefined);  
  const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
  const [page, setPage] = useState<number | undefined>(undefined);
  const { user } = useConfig();

  const loansRepository: LoansRepository = RestLoansRepository.getInstance();

  const loadUserLoans = async (page: number = 1) => {
    setLoading(true);
    const [paginatedLoans, errorMessage] = await loansRepository.loansByUser(user!.id, page);

    if(errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setLoans(paginatedLoans?.data);
      setTotalPages(paginatedLoans?.totalPages);
      setPage(paginatedLoans?.currentPage);
    }
    setLoading(false);
  };
 
  return {
    totalPages,
    page,
    loadUserLoans,
    loans,
    loading
  };
};

export default useLoans;