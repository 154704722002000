import { Constants } from "../../utils";

interface IUser {
  id: string;
  name: string;
  email: string;
  password?: string;
  inArrears: boolean;
  hasActiveCredit: boolean;
  personalInformationComplete: boolean;
  firstName?: string;
  secondName?: string;
  firstSurname?: string;
  secondSurname?: string;
  birthDate?: Date;
  mobilePhone?: string;
  phone?: string;
  genderCd?: number;
  maritalStatusCd?: number;
  documentNumber?: string;
  documentExpeditionDate?: Date;
  documentTypeCd?: number;
}

export default class User {
  id: string;
  name: string;
  email: string;
  password?: string;
  inArrears: boolean;
  hasActiveCredit: boolean;
  personalInformationComplete: boolean;
  firstName?: string;
  secondName?: string;
  firstSurname?: string;
  secondSurname?: string;
  birthDate?: Date;
  mobilePhone?: string;
  phone?: string;
  genderCd?: number;
  maritalStatusCd?: number;
  documentNumber?: string;
  documentExpeditionDate?: Date;
  documentTypeCd?: number;

  constructor({
    id, name,
    email, password,
    inArrears, hasActiveCredit, personalInformationComplete,
    firstName, secondName,
    firstSurname, secondSurname,
    birthDate, mobilePhone,
    phone, genderCd,
    maritalStatusCd, documentNumber,
    documentExpeditionDate, documentTypeCd,
  }: IUser) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.password = password;
    this.inArrears = inArrears;
    this.hasActiveCredit = hasActiveCredit;
    this.personalInformationComplete = personalInformationComplete;
    this.firstName = firstName;
    this.secondName = secondName;
    this.firstSurname = firstSurname;
    this.secondSurname = secondSurname;
    this.birthDate = birthDate;
    this.mobilePhone = mobilePhone;
    this.phone = phone;
    this.genderCd = genderCd;
    this.maritalStatusCd = maritalStatusCd;
    this.documentNumber = documentNumber;
    this.documentExpeditionDate = documentExpeditionDate;
    this.documentTypeCd = documentTypeCd;
  } 

  get fullName() {
    return `${this.firstName ?? ''} ${this.secondName ?? ''} ${this.firstSurname ?? ''} ${this.secondSurname ?? ''}`;
  }

  get shortDocumentType() {
    switch (this.documentTypeCd) {
      case Constants.DOCUMENT_TYPES['Cedula de ciudadania']:
        return 'cc';
      case Constants.DOCUMENT_TYPES['Cedula de extranjeria']:
        return 'ce';
      default:
        throw new Error(`Unknown type: ${this.documentTypeCd}`);
    }
  }
}