import { Session, User } from "../../../domain/models";
import { UsersRepository } from "../../../domain/repositories";
import { updatePersonalInformationPayload } from "../../../domain/repositories/UsersRepository";

export default class MockUsersRepository implements UsersRepository {
  private static instance: MockUsersRepository;

  private constructor() { }

  static getInstance() {
    if (!this.instance) {
      this.instance = new MockUsersRepository();
    }
    return this.instance;
  }

  login(email: string, password: string): Promise<[Session?, string?]> {
    return new Promise<[Session?, string?]>((resolve, reject) => {
      setTimeout(() => {
        if (email.includes('error')) {
          resolve([undefined, 'Usuario o contraseña incorrectos']);
        } else {
          resolve([new Session({ token: 'token' }), undefined]);
        }
      }, 3000);
    });
  }

  signUp(first_name: string, email: string, mobile_phone: string, password: string): Promise<[Session?, string?]> {
    return new Promise<[Session?, string?]>((resolve, reject) => {
      setTimeout(() => {
        if (email.includes('error')) {
          resolve([undefined, 'Email ya esta registrado']);
        } else {
          resolve([new Session({ token: 'token' }), undefined]);
        }
      }, 3000);
    });
  }

  updatePassword(oldPassword: string, newPassword: string): Promise<[boolean, string?]> {
    return new Promise<[boolean, string?]>((resolve, reject) => {
      setTimeout(() => {
        if (oldPassword.includes('error')) {
          resolve([false, 'Contraseña actual incorrecta']);
        } else {
          resolve([true, undefined]);
        }
      }, 3000);
    });
  }

  resetPassword(email: string): Promise<[boolean, string?]> {
    return new Promise<[boolean, string?]>((resolve, reject) => {
      setTimeout(() => {
        if (email.includes('error')) {
          resolve([false, 'Error al intentar obtener nueva contraseña']);
        } else {
          resolve([true, undefined]);
        }
      }, 3000);
    });
  };

  async updatePersonalInformation(personalInformation: updatePersonalInformationPayload): Promise<[User?, string?]> {
    return new Promise<[User?, string?]>((resolve, reject) => {
      setTimeout(() => {
        if (personalInformation.first_name.includes('error')) {
          resolve([undefined, 'Error al actualizar']);
        } else {
          resolve([
            new User({ id: '1', name: 'John', email: '123@gmail.com', password: 'John Doe', inArrears: false, hasActiveCredit: false, personalInformationComplete: true }),
            undefined
          ]);
        }
      }, 3000);
    });
  };
}