import { Navigate } from "react-router-dom";
import { NewLoanBankingDataForm, NewLoanFinancialDataForm, NewLoanReferencesForm, PageContainer, Stepper, Splash } from "../components";
import { useApplyForCreditForm, useConfig, usePageTracking } from "../hooks";
import Routes from "../../config/Routes";

const NewLoanPage = () => {
  const TOTAL_STEPS = 4;
  const {
    step, goToNextStep,
    goToPreviousStep,
    bankingDataHandler, financialDataHandler,
    referencesDataHandler, loading
  } = useApplyForCreditForm(TOTAL_STEPS);
  const { user } = useConfig();
  usePageTracking();

  const steps = [
    <NewLoanBankingDataForm handler={bankingDataHandler}  onNext={goToNextStep}/>,
    <NewLoanFinancialDataForm handler={financialDataHandler}  onNext={goToNextStep} onPrevious={goToPreviousStep} />,
    <NewLoanReferencesForm handler={referencesDataHandler}  onPrevious={goToPreviousStep}/>
  ];

  if (user?.inArrears || user?.hasActiveCredit) {
    return <Navigate to={Routes.HOME_PATH} replace />;
  }

  if (!user?.personalInformationComplete) {
    return <Navigate to={`${Routes.UPDATE_PERSONAL_INFORMATION}?from=new-loan`} replace />;
  }

  if(loading) {
    return(<Splash/>)
  }
  
  return (
    <PageContainer pageTitle="Créditos de rápido desembolso" className="flex-col">
      <h1 className="text-4xl font-bold">Solicitar préstamo</h1>
      <Stepper
        className="mt-5 md:mt-10"
        steps={["Datos bancarios", "Datos financieros", "Referencias"]}
        currentStep={step}
      />

      <div className="flex flex-wrap bg-white mt-5 rounded-lg shadow-sm border border-gray-200 py-10 px-6 md:p-20">
        {steps[step]}
      </div>
    </PageContainer>
  )
}

export default NewLoanPage;