import { Email, Text, CheckboxInput } from "../../../infrastructure/inputs";
import { Environment } from "../../../utils";
import { useNewLoanReferencesForm } from "../../hooks";
import Button from "../shared/Button";
import Input from "../shared/Input";
import Checkbox from "../shared/Checkbox";

interface NewLoanReferencesFormProps {
  className?: string;
  onPrevious?: () => void;
  handler: ReturnType<typeof useNewLoanReferencesForm>;
}

const NewLoanReferencesForm: React.FC<NewLoanReferencesFormProps> = ({ className = '', onPrevious, handler }) => {
  const {
    personalReferenceName, setPersonalReferenceName,
    personalReferencePhone, setPersonalReferencePhone,
    personalReferenceEmail, setPersonalReferenceEmail,
    personalReferenceRelationship, setPersonalReferenceRelationship,
    familyReferenceName, setFamilyReferenceName,
    familyReferencePhone, setFamilyReferencePhone,
    familyReferenceEmail, setFamilyReferenceEmail,
    familyReferenceRelationship, setFamilyReferenceRelationship,
    acceptedTerms, setAcceptedTerms,
    submitted, submitForm,
    developmentAutoFill
  } = handler;

  return (
    <form
      className={`${className} flex flex-wrap flex-col w-full`}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}
    >
      <h2 className="font-bold text-xl">Referencia personal</h2>
      <div className="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full font-medium gap-5 mt-5">
        <Input
          placeholder="Nombre"
          value={personalReferenceName.value}
          errorMessage={submitted ? personalReferenceName.errorMessage() : null}
          onChange={(event) => { setPersonalReferenceName(Text.dirty(event.target.value)); }}
        />

        <Input
          placeholder="Teléfono de contacto"
          type="number"
          value={personalReferencePhone.value}
          errorMessage={submitted ? personalReferencePhone.errorMessage() : null}
          onChange={(event) => { setPersonalReferencePhone(Text.dirty(event.target.value)); }}
        />

        <Input
          placeholder="Email"
          type="email"
          value={personalReferenceEmail.value}
          errorMessage={submitted ? personalReferenceEmail.errorMessage() : null}
          onChange={(event) => { setPersonalReferenceEmail(Email.dirty(event.target.value)); }}
        />

        <Input
          placeholder="Relación"
          value={personalReferenceRelationship.value}
          errorMessage={submitted ? personalReferenceRelationship.errorMessage() : null}
          onChange={(event) => { setPersonalReferenceRelationship(Text.dirty(event.target.value)); }}
        />
      </div>

      <h2 className="font-bold text-xl mt-10">Referencia familiar</h2>

      <div className="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full font-medium gap-5 mt-5">
        <Input
          placeholder="Nombre"
          value={familyReferenceName.value}
          errorMessage={submitted ? familyReferenceName.errorMessage() : null}
          onChange={(event) => { setFamilyReferenceName(Text.dirty(event.target.value)); }}
        />

        <Input
          placeholder="Teléfono de contacto"
          value={familyReferencePhone.value}
          errorMessage={submitted ? familyReferencePhone.errorMessage() : null}
          onChange={(event) => { setFamilyReferencePhone(Text.dirty(event.target.value)); }}
        />

        <Input
          placeholder="Email"
          value={familyReferenceEmail.value}
          errorMessage={submitted ? familyReferenceEmail.errorMessage() : null}
          onChange={(event) => { setFamilyReferenceEmail(Email.dirty(event.target.value)); }}
        />

        <Input
          placeholder="Relación"
          value={familyReferenceRelationship.value}
          errorMessage={submitted ? familyReferenceRelationship.errorMessage() : null}
          onChange={(event) => { setFamilyReferenceRelationship(Text.dirty(event.target.value)); }}
        />

        <Checkbox
          value={acceptedTerms.getBoolean()}
          errorMessage={submitted ? acceptedTerms.errorMessage() : null}
          onChange={(value) => { setAcceptedTerms(CheckboxInput.dirty(String(value))); }}
        >
          <label className="text-start opacity-65">Acepto los <a href={Environment.termsUrl} target='_blank' rel='noreferrer' className="text-sky-500 hover:text-sky-700">términos y condiciones</a></label>
        </Checkbox>
      </div>

      {
        Environment.env === 'development' && (
          <button className="absolute right-20" type="button" onClick={() => {
            developmentAutoFill();
          }}>Autofill</button>
        )
      }

      <div className="flex justify-between w-full mt-16">
        <Button loading={false} disabled={false} className="w-auto" text="Anterior" onClick={onPrevious} />
        <Button loading={false} disabled={false} className="w-auto" text="Aplicar" type="submit" />
      </div>
    </form>
  )
}

export default NewLoanReferencesForm;