import { useState } from 'react';
import { LoansRepository } from '../../domain/repositories';
import { RestLoansRepository } from '../../infrastructure/repositories';
import { PaymentTransaction } from '../../domain/models';
import { Alert } from '../../utils';

const useLoanPaymentTransactions = () => {
  const [loading, setLoading] = useState(false);
  const [paymentTransactions, setPaymentTransactions] = useState<PaymentTransaction[]>([]);

  const loansRepository: LoansRepository = RestLoansRepository.getInstance();

  const loadPaymentTransactions = async (loanId: string) => {
    setLoading(true);
    const [transactions, errorMessage] = await loansRepository.paymentTransactions(loanId);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setPaymentTransactions(transactions ?? []);
    }
    setLoading(false);
  }

  return {
    loading,
    paymentTransactions,
    loadPaymentTransactions
  };
};

export default useLoanPaymentTransactions;