import { BankAccount } from "../../../../domain/models";
import { BankAccountEntity } from "../entities";
import { BankMapper } from ".";

export default class BankAccountMapper {
  static toModel(entity: BankAccountEntity): BankAccount {
    return new BankAccount({
      number: entity.number,
      type_cd: entity.type_cd,
      bank: entity.bank ? BankMapper.toModel(entity.bank!) : undefined
    })
  }
}