import { useState } from 'react';
import { InputValidator, Password } from '../../infrastructure/inputs';
import { UsersRepository } from '../../domain/repositories';
import { RestUsersRepository } from '../../infrastructure/repositories';
import { useNavigate } from 'react-router-dom';
import Routes from '../../config/Routes';
import { Alert, Constants } from '../../utils';

const useUpdatePasswordForm = () => {
  const [oldPassword, setOldPassword] = useState(Password.dirty(''));
  const [newPassword, setNewPassword] = useState(Password.dirty(''));
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState(Password.dirty(''));
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const usersRepository: UsersRepository = RestUsersRepository.getInstance();

  const submitForm = async () => {
    setSubmitted(true);
    if(!newPasswordMatches()) {
      Alert.showError(Constants.NON_MATCHING_PASSWORDS_ERROR_MESSAGE);
      return;
    }
    if (!validForm()) return;

    setLoading(true);
    const [succeeded, errorMessage]: [boolean, string?] = await usersRepository.updatePassword(oldPassword.value, newPassword.value);
    setLoading(false);

    if (!succeeded) {
      Alert.showError(errorMessage!);
    } else {
      Alert.showSuccess(Constants.UPDATED_PASSWORD_SUCCESSFULLY);
      navigate(Routes.ACCOUNT_PATH, { replace: true });
    }
  };

  const validForm = (): boolean => {
    return InputValidator.valid([oldPassword, newPassword, newPasswordConfirmation]) && newPasswordMatches();
  }

  const newPasswordMatches = (): boolean => newPassword.value === newPasswordConfirmation.value;

  return {
    loading,
    oldPassword,
    setOldPassword,
    newPassword,
    setNewPassword,
    newPasswordConfirmation,
    setNewPasswordConfirmation,
    submitted,
    submitForm
  };
};

export default useUpdatePasswordForm;