import { NavLink } from "react-router-dom";
import { PageContainer, UpdatePasswordForm } from "../components";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Routes from "../../config/Routes";
import { usePageTracking } from "../hooks";

const UpdatePasswordPage = () => {
  usePageTracking();

  return (
    <PageContainer className="flex-col">
      <h1 className="text-4xl font-bold">Actualizar contraseña</h1>
      
      <div className="mt-8 bg-white rounded-lg shadow-lg flex-col flex">
        <NavLink className="flex justify-start pl-10 pt-10 items-center font-bold opacity-65" to={Routes.ACCOUNT_PATH}>
          <ArrowBackIosIcon style={{ fontSize: '1rem' }} className="text-gray-500 hover:text-gray-400"/>
          Volver
        </NavLink>
        <UpdatePasswordForm />
      </div>
    </PageContainer>
  )
}

export default UpdatePasswordPage;