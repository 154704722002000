interface ISession {
  token: string;
}

export default class Session {
  token: string;

  constructor({ token }: ISession) {
    this.token = token;
  }
}