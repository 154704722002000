import AbstractInput from './AbstractInput';

enum HomePhoneError { empty, format }

export default class HomePhone extends AbstractInput<string, HomePhoneError> {
  REGEX = /^(?:\+57)?\s?(1)?[-\s]?(\d{7})$/;

  empty = () => {
    return this.value === null || this.value === undefined || this.value.trim() === '';
  };

  errorMessage = () => {
    const displayError = this.validator();

    if (displayError === HomePhoneError.empty) return 'El campo teléfono es requerido';
    if (displayError === HomePhoneError.format) return 'Teléfono no tiene formato correcto';
    return null;
  };

  validator = () => {
    if(!this.allowEmpty) {
      if (this.empty()) return HomePhoneError.empty;
    }
    if (this.empty()) return null;

    if (!this.REGEX.test(this.value)) return HomePhoneError.format;

    return null;
  };
}