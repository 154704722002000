import { LoanApplicationDeprecated } from "../../../../domain/models";

type Json = {
  [key: string]: any;
}

export default class LoanApplicationMapperDeprecated {
  static toJson(loanApplication: LoanApplicationDeprecated): Json {
    return {
      "amount_requested": loanApplication.amountRequested,
      "days": loanApplication.creditDays,
      "purpose": loanApplication.creditPurpose,
      "first_name": loanApplication.firstName,
      "second_name": loanApplication.middleName,
      "first_surname": loanApplication.firstLasName,
      "second_surname": loanApplication.middleLastName,
      "birth_date": loanApplication.birthday,
      "document_type_cd": loanApplication.documentType,
      "document_number": loanApplication.documentNumber,
      "document_expedition_date": loanApplication.documentExpeditionDate,
      "address": loanApplication.address,
      "city": loanApplication.city,
      "estate": loanApplication.state,
      "layer": loanApplication.layer,
      "years_of_living_cd": loanApplication.yearsOfLiving,
      "people_in_charge": loanApplication.peopleInCharge,
      "number_of_children": loanApplication.numberOfChildren,
      "mobile_phone": loanApplication.mobilePhone,
      "phone": loanApplication.phone,
      "education": loanApplication.educationLevel,
      "income": loanApplication.monthlyIncome,
      "home_income": loanApplication.homeMonthlyIncome,
      "lives_with_cd": loanApplication.homeTenants,
      "vehicle_type_cd": loanApplication.vehicleType,
      "vehicle_plates": loanApplication.vehiclePlate,
      "marital_status_cd": loanApplication.civilStatus,
      "gender_cd": loanApplication.gender,
      "occupation_cd": loanApplication.occupation,
      "company": loanApplication.company,
      "company_phone": loanApplication.companyPhone,
      "personal_reference_name": loanApplication.personalReferenceName,
      "personal_reference_phone": loanApplication.personalReferencePhone,
      "personal_reference_email": loanApplication.personalReferenceEmail,
      "personal_reference_relation": loanApplication.personalReferenceRelationship,
      "family_reference_name": loanApplication.familyReferenceName,
      "family_reference_phone": loanApplication.familyReferencePhone,
      "family_reference_email": loanApplication.familyReferenceEmail,
      "family_reference_relation": loanApplication.familyReferenceRelationship,
      "bank_account": {
        "number": loanApplication.bankAccountNumber,
        "type_cd": loanApplication.bankAccountType,
        "code": loanApplication.bankCode
      }
    }
  }
}