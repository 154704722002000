import { Number, Selector } from "../../../infrastructure/inputs";
import { Constants, Environment } from "../../../utils";
import { useNewLoanBankingDataForm } from "../../hooks";
import Button from "../shared/Button";
import Input from "../shared/Input";
import OptionInput from "../shared/OptionInput";

interface NewLoanBankingDataFormProps {
  className?: string;
  onNext?: () => void;
  handler: ReturnType<typeof useNewLoanBankingDataForm>;
}

const NewLoanBankingDataForm: React.FC<NewLoanBankingDataFormProps> = ({ className = '', onNext, handler }) => {
  const {
    submitForm,
    bankAccountNumber,
    bankAccountType,
    bankCode,
    setBankCode,
    setBankAccountType,
    setBankAccountNumber,
    submitted,
    developmentAutoFill,
    config
  } = handler;

  const submit = () => {
    const submittedCorrectly = submitForm();
    if (submittedCorrectly) {
      onNext?.();
    }
  }

  return (
    <form
      className={`${className} flex flex-wrap flex-col w-full`}
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      <h2 className="font-bold text-xl">¿Donde consignaremos el dinero en caso de aprobado?</h2>

      <div className="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full font-medium gap-5 mt-5">
        <OptionInput
          options={Object.keys(Constants.BANK_ACCOUNT_TYPES).map((key) => ({ value: Constants.BANK_ACCOUNT_TYPES[key], label: key }))}
          selectedOption={bankAccountType.value}
          className=''
          placeholder="Tipo de cuenta"
          errorMessage={submitted ? bankAccountType.errorMessage() : null}
          onChange={(event) => { setBankAccountType(Selector.dirtyWithOptions(event.target.value, Object.values(Constants.BANK_ACCOUNT_TYPES))) }}
        />

        <OptionInput
          options={config?.banks.map(bank => ({ value: bank.code, label: bank.name }))}
          selectedOption={bankCode.value}
          className=''
          placeholder="Banco"
          errorMessage={submitted ? bankCode.errorMessage() : null}
          onChange={(event) => { setBankCode(Selector.dirtyWithOptions(event.target.value, config?.banks.map(bank => bank.code) ?? [])) }}
        />

        <Input
          placeholder="Número de cuenta"
          value={bankAccountNumber.value}
          errorMessage={submitted ? bankAccountNumber.errorMessage() : null}
          onChange={(event) => { setBankAccountNumber(Number.dirty(event.target.value)) }}
        />
      </div>

      {
        Environment.env === 'development' && (
          <button className="absolute right-20" type="button" onClick={() => {
            developmentAutoFill();
          }}>Autofill</button>
        )
      }

      <div className="flex justify-end w-full mt-16">
        <Button loading={false} disabled={false} className="w-auto" type="submit" text="Siguiente" />
      </div>
    </form>
  )
}

export default NewLoanBankingDataForm;