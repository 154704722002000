import moment from 'moment-timezone';

export default class DateUtil {
  static formatDate = (date: Date): string => {
    const receivedDate = new Date(date.toString());
    const formatter = new Intl.DateTimeFormat('es-ES', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
    return formatter.format(receivedDate).replace(' de ', ' ');
  }

  static isValidDate = (dateString: string): boolean => {
    if (!moment(dateString).isValid()) {
      return false;
    }

    return true;
  }

  static toDate = (dateString: string): Date => {
    if (!this.isValidDate(dateString)) {
      throw Error('Invalid date');
    }

    const dateInTimezone = moment.tz(dateString, 'America/Bogota').toDate();
    return dateInTimezone;
  }
}