import { Helmet } from "react-helmet";
import { Navigation } from "../";

interface PageContainerProps {
  pageTitle?: String;
  children: React.ReactNode;
  className?: string;
}

const PageContainer: React.FC<PageContainerProps> = ({ pageTitle, children, className }) => {
  return (
    <div className="flex flex-wrap">
      <Helmet>
        <title>Prestaseguro{pageTitle ? ` | ${pageTitle}` : ''}</title>
      </Helmet>
      <div className="w-screen flex flex-row mb-16 lg:mb-0">
        <Navigation />
        <div className={`flex ${className} p-5 lg:px-16 lg:py-10 w-full lg:ml-64`}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default PageContainer;