interface IBank {
  name: string;
  code: string;
}

export default class Bank {
  name: string;
  code: string;

  constructor({ name, code }: IBank) {
    this.name = name;
    this.code = code;
  }
}