import { useUpdatePasswordForm } from '../hooks';
import { Button, Input } from '.';
import { InputValidator, Password } from '../../infrastructure/inputs';

interface UpdatePasswordFormProps {
  className?: string;
}

const UpdatePasswordForm: React.FC<UpdatePasswordFormProps> = ({ className = '' }) => {
  const {
    oldPassword,
    newPassword,
    newPasswordConfirmation,
    setOldPassword,
    setNewPassword,
    setNewPasswordConfirmation,
    loading,
    submitted,
    submitForm
  } = useUpdatePasswordForm();

  return (
    <form className={`${className} flex flex-wrap flex-col px-20 pb-20 pt-10 sm:justify-center items-center space-y-5`} onSubmit={(event) => { event.preventDefault(); submitForm(); }}>
      <Input
        className='mt-5'
        placeholder="Contraseña actual"
        type="password"
        value={oldPassword.value}
        errorMessage={submitted ? oldPassword.errorMessage() : null}
        onChange={(event) => { setOldPassword(Password.dirty(event.target.value)) }}
      />

      <Input
        className='mt-5'
        placeholder="Contraseña"
        type="password"
        value={newPassword.value}
        errorMessage={submitted ? newPassword.errorMessage() : null}
        onChange={(event) => { setNewPassword(Password.dirty(event.target.value)) }}
      />

      <Input
        className='mt-5'
        placeholder="Confirmar contraseña"
        type="password"
        value={newPasswordConfirmation.value}
        errorMessage={submitted ? newPasswordConfirmation.errorMessage() : null}
        onChange={(event) => { setNewPasswordConfirmation(Password.dirty(event.target.value)) }}
      />

      <Button loading={loading} disabled={InputValidator.anyEmpty([oldPassword, newPassword, newPasswordConfirmation])} className="mt-8" text="Actualizar" type='submit'/>
    </form>
  )
}

export default UpdatePasswordForm;