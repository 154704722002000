import { BeatLoader } from "react-spinners";

interface ButtonProps {
  onClick?: () => void;
  text: String;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
}

const Button: React.FC<ButtonProps> = ({ onClick, text, disabled = true, className = '', loading = false, type = 'button' }) => {
  const style = disabled || loading ? 'bg-violet-600 opacity-60 text-opacity-60' : 'bg-violet-500 hover:bg-violet-700';

  return (
    <button
      type={type}
      disabled={disabled || loading}
      className={`${className} rounded-full ${style} text-white py-4 px-6 text-center justify-center items-center flex`}
      onClick={onClick}
    >
      {
        loading ? (
          <BeatLoader
            color={'white'}
            className="h-6 w-auto justify-center items-center opacity-20"
            aria-label="Loading Spinner"
          />     
        ) : (
            text
        )
      }
    </button>
  )
}

export default Button;