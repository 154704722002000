import { LoanApplication, OnlinePaymentInfo } from "./";
import { FeeInfo } from "./";

export enum LoanStatus {
  open = "Abierto",
  approved = "Aprobado",
  deposited = "Depositado",
  paid = "Pagado",
  rejected = "Rechazado",
  pending = "Pendiente",
  canceled = "Cancelado"
}

export enum PaymentMethod {
  transfer = "Transferencia",
  online = "En línea"
}

interface ILoan {
  id: string;
  createdAt: Date;
  status: LoanStatus;
  paymentUrl?: string;
  amountApproved?: number;
  amountToPay?: number;
  amountPaid?: number;
  approvedConsecutive?: number;
  annualEffectiveInterestRate?: number;
  negotiatedAmountToPay?: number;
  negotiatedPaymentDate?: Date;
  chargedInterest?: number;
  fees?: FeeInfo[];
  consecutive: number;
  estimatedPaymentDate?: Date;
  paidAt?: Date;
  depositedAt?: Date;
  loanApplication: LoanApplication;
  receiptUrl?: string;
  onlinePaymentInfo?: OnlinePaymentInfo;
  paymentMethod?: PaymentMethod;
}

export default class Loan {
  id: string;
  createdAt: Date;
  status: LoanStatus;
  paymentUrl?: string;
  amountApproved?: number;
  approvedConsecutive?: number;
  amountToPay?: number;
  amountPaid?: number;
  annualEffectiveInterestRate?: number;
  chargedInterest?: number;
  fees: FeeInfo[];
  consecutive: number;
  estimatedPaymentDate?: Date;
  paidAt?: Date;
  depositedAt?: Date;
  loanApplication: LoanApplication;
  receiptUrl?: string;
  negotiatedAmountToPay?: number;
  negotiatedPaymentDate?: Date;
  onlinePaymentInfo?: OnlinePaymentInfo;
  paymentMethod?: PaymentMethod;

  constructor({
    id, createdAt, status, paymentUrl = undefined,
    amountApproved, amountToPay, consecutive, approvedConsecutive,
    estimatedPaymentDate, depositedAt, loanApplication,
    paidAt, receiptUrl, annualEffectiveInterestRate,
    negotiatedAmountToPay, negotiatedPaymentDate,
    chargedInterest, fees = [], amountPaid, onlinePaymentInfo,
    paymentMethod
  }: ILoan) {
    this.id = id;
    this.createdAt = createdAt;
    this.status = status;
    this.paymentUrl = paymentUrl;
    this.amountApproved = amountApproved;
    this.amountToPay = amountToPay;
    this.annualEffectiveInterestRate = annualEffectiveInterestRate;
    this.chargedInterest = chargedInterest;
    this.fees = fees;
    this.consecutive = consecutive;
    this.estimatedPaymentDate = estimatedPaymentDate;
    this.paidAt = paidAt;
    this.depositedAt = depositedAt;
    this.loanApplication = loanApplication;
    this.receiptUrl = receiptUrl;
    this.amountPaid = amountPaid;
    this.negotiatedAmountToPay = negotiatedAmountToPay;
    this.negotiatedPaymentDate = negotiatedPaymentDate;
    this.approvedConsecutive = approvedConsecutive;
    this.onlinePaymentInfo = onlinePaymentInfo;
    this.paymentMethod = paymentMethod;
  }

  availableForPayment = (): boolean => {
    return this.status === LoanStatus.deposited;
  }

  inArrears = (): boolean => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if(this.estimatedPaymentDate === undefined) {
      return false;
    }

    return this.estimatedPaymentDate < currentDate && this.status === LoanStatus.deposited;
  }

  active = (): boolean => {
    return this.status === LoanStatus.open || this.status === LoanStatus.approved || this.status === LoanStatus.deposited || this.status === LoanStatus.pending;
  }
}