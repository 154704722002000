import { Bank } from ".";

interface IBankAccount {
  number: string;
  type_cd: number;
  bank?: Bank;
}

export default class BankAccount {
  number: string;
  type_cd: number;
  bank?: Bank;

  constructor({ number, type_cd, bank }: IBankAccount) {
    this.number = number;
    this.type_cd = type_cd;
    this.bank = bank;
  }
}