import BottomNavigationOption from "./BottomNavigationOption";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import Routes from "../../../config/Routes";
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';

interface BottomNavigationProps {
  className?: string;
}
const BottomNavigation: React.FC<BottomNavigationProps> = ({ className }) => {
  return (
    <div className={`${className} fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600`}>
      <div className="grid h-full max-w-lg grid-cols-5 mx-auto font-medium">
        <span></span>
        <BottomNavigationOption name="Créditos" to={Routes.HOME_PATH} icon={<HomeRoundedIcon />} />
        <BottomNavigationOption name="Cuenta" to={Routes.ACCOUNT_PATH} icon={<Person2RoundedIcon />} />
        <BottomNavigationOption name="Pago" to={Routes.PAYMENT_METHODS_PATH} icon={<PaymentsRoundedIcon />} />
      </div>
    </div>
  )
}
export default BottomNavigation;