import { ConfigRepository } from '../../domain/repositories';
import { Alert } from '../../utils';
import { RestConfigRepository } from '../../infrastructure/repositories';
import { useDispatch, useSelector } from 'react-redux';
import { configSlice } from '../../infrastructure/redux/slices';
import { selectConfig } from '../../infrastructure/redux/selectors';
import { User, Config, BankAccount } from '../../domain/models';


const useConfig = () => {
  const config: Config | undefined | null = useSelector(selectConfig);
  const user: User | undefined | null = config?.user;
  const bankAccount: BankAccount | undefined | null = config?.bankAccount;
  const dispatch = useDispatch();

  const configRepository: ConfigRepository = RestConfigRepository.getInstance();

  const loadConfig = async () => {
    const [config, errorMessage] = await configRepository.checkin();

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      dispatch(configSlice.actions.saveConfig(config!));
    }
  };

  const updateUser = (user: User) => {
    dispatch(configSlice.actions.updateUser(user));
  }

  return {
    loadConfig,
    updateUser,
    config,
    user,
    bankAccount
  };
};

export default useConfig;