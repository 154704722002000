interface ILoanSupportFile {
  url: string;
}

export default class LoanSupportFile {
  url: string;

  constructor({
    url
  }: ILoanSupportFile) {
    this.url = url;
  }
}