import { useEffect, useState } from "react";
import { Constants, Environment } from "../../utils";
import { Loan } from "../../domain/models";
import useConfig from "./useConfig";

declare global {
  interface Window {
    ePayco: any;
  }
}

const useEpaycoCheckout = () => {
  const [handler, setHandler] = useState<any>(null);
  const { user } = useConfig();

  useEffect(() => {
    if (window.ePayco) {
      const configuredHandler = window.ePayco.checkout.configure({
        key: Environment.epaycoApiKey,
        'data-epayco-test': Environment.env === 'development',
        test: Environment.env === 'development',
      });
      setHandler(configuredHandler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToCheckout = (loan: Loan) => {
    let payload = checkoutPayloadForLoan(loan);
    if (Environment.env === 'test') {
      payload = overrideAmountsForDevelopment(payload);
    }

    handler.open(payload);
  }

  const overrideAmountsForDevelopment = (data: any) => {
    data.amount = 10000;
    data.tax_base = 0;
    data.tax = 0;
    return data;
  }

  const checkoutPayloadForLoan = (loan: Loan) => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();

    return {
      name: loan.id,
      description: `Pago de ${loan.id}`,
      invoice: `${loan.approvedConsecutive!}-${currentHour}`,
      currency: Constants.COP_CURRENCY,
      amount: loan.onlinePaymentInfo!.amountToPay,
      tax_base: loan.onlinePaymentInfo!.amountToPayBeforeIva,
      tax: loan.onlinePaymentInfo!.iva,
      country: Constants.COLOMBIA_SHORT_NAME,
      lang: Constants.SPANISH_LANGUAGE,
      external: 'false',
      extra1: `Fecha de generacion del vinculo ${(new Date()).toISOString()}`,
      response: `${window.location.protocol}//${window.location.hostname}`,
      confirmation: `${Environment.backendUrl}/public/v1/loans/${loan.id}/epayco_payment_confirmation`,
      name_billing: user!.fullName,
      address_billing: loan.loanApplication.address,
      type_doc_billing: user!.shortDocumentType,
      number_doc_billing: user!.documentNumber,
      mobilephone_billing: user!.mobilePhone,
    }
  }

  return {
    goToCheckout
  }
};

export default useEpaycoCheckout;