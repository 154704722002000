import { BeatLoader } from "react-spinners";
import { Copyright } from "../";

const Splash = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <BeatLoader
        aria-label="Loading Spinner"
       />
      <Copyright className="text-slate-300 text-sm hidden md:flex flex-row absolute bottom-0 right-0 pb-5 px-10 justify-end space-x-10"/>
    </div>
  )
}

export default Splash;
