import AbstractInput from './AbstractInput';

enum CheckboxError { empty, format }

export default class CheckboxInput extends AbstractInput<string, CheckboxError> {

  empty = () => {
    return this.value === null || this.value === undefined || this.value.trim() === '' || this.value.trim() === 'false';
  };

  errorMessage = () => {
    const displayError = this.validator();

    if (displayError === CheckboxError.empty) return 'El campo es requerido';
    if (displayError === CheckboxError.format) return 'El campo no tiene formato correcto';
    return null;
  };

  validator = () => {
    if (!this.allowEmpty) {
      if (this.empty()) return CheckboxError.empty;
    }
    if (this.empty()) return null;

    if (this.value.trim() !== 'false' && this.value.trim() !== 'true') return CheckboxError.format;

    return null;
  };

  getBoolean = (): boolean => {
    if(this.value.trim() === 'true') return true;

    return false;
  }
}