import { Number, Selector, Text } from "../../../infrastructure/inputs";
import { Cities, Constants, Environment, Money } from "../../../utils";
import { useNewLoanFinancialDataForm } from "../../hooks";
import Button from "../shared/Button";
import Input from "../shared/Input";
import OptionInput from "../shared/OptionInput";

interface NewLoanFinancialDataFormProps {
  className?: string;
  onPrevious?: () => void;
  onNext?: () => void;
  handler: ReturnType<typeof useNewLoanFinancialDataForm>;
}

const NewLoanFinancialDataForm: React.FC<NewLoanFinancialDataFormProps> = ({ className = '', onPrevious, onNext, handler }) => {
  const {
    state, setState,
    layer, setLayer,
    yearsOfLiving, setYearsOfLiving,
    homeTenants, setHomeTenants,
    peopleInCharge, setPeopleInCharge,
    numberOfChildren, setNumberOfChildren,
    monthlyIncome, setMonthlyIncome,
    homeMonthlyIncome, setHomeMonthlyIncome,
    educationLevel, setEducationLevel,
    vehicleType, setVehicleType,
    vehiclePlate, setVehiclePlate,
    occupation, setOccupation,
    company, setCompany,
    companyPhone, setCompanyPhone,
    city, setCity,
    creditPurpose, setCreditPurpose,
    creditDays, setCreditDays,
    amountRequested, setAmountRequested,
    submitted, submitForm,
    developmentAutoFill, config,
    termRange, amountRange,
    address, setAddress,
    states, cities
  } = handler;

  const submit = () => {
    const submittedCorrectly = submitForm();
    if (submittedCorrectly) {
      onNext?.();
    }
  }

  return (
    <form
      className={`${className} flex flex-wrap flex-col w-full`}
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      <h2 className="font-bold text-xl">Datos crédito</h2>
      <div className="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full font-medium gap-5 mt-5">        
        <OptionInput
          options={
            amountRange.map((step) => ({ value: step, label: Money.formatToCOP(parseInt(step)).toString() }))
          }
          selectedOption={amountRequested.value}
          className=''
          placeholder="¿Cuánto dinero necesitas?"
          errorMessage={submitted ? amountRequested.errorMessage() : null}
          onChange={(event) => { setAmountRequested(Selector.dirtyWithOptions(event.target.value, amountRange)) }}
        />

        <OptionInput
          options={
            termRange.map((step) => ({ value: step, label: `${step} días` }))
          }
          selectedOption={creditDays.value}
          className=''
          placeholder="¿Durante cuánto tiempo?"
          errorMessage={submitted ? creditDays.errorMessage() : null}
          onChange={(event) => { setCreditDays(Selector.dirtyWithOptions(event.target.value, termRange)) }}
        />

        <OptionInput
          options={config?.purposes.map(purpose => ({ value: purpose.id, label: purpose.name }))}
          selectedOption={creditPurpose.value}
          className=''
          placeholder="¿Para que necesitas el dinero?"
          errorMessage={submitted ? creditPurpose.errorMessage() : null}
          onChange={(event) => { setCreditPurpose(Selector.dirtyWithOptions(event.target.value, config?.purposes.map(purpose => purpose.id) ?? [])) }}
        />
      </div>

      <h2 className="font-bold text-xl mt-10">Datos financieros</h2>

      <div className="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full font-medium gap-5 mt-5">
        <OptionInput
          options={states.map((state) => ({ value: state, label: state }))}
          selectedOption={state.value}
          className=''
          placeholder="Departamento"
          errorMessage={submitted ? state.errorMessage() : null}
          onChange={(event) => {
            setCity(Selector.dirtyWithOptions('', cities))
            setState(Selector.dirtyWithOptions(event.target.value, states))
          }}
        />

        <OptionInput
          disabled={state.empty()}
          options={Cities.COLOMBIA.filter((city) => city.state === state.value).map((city) => ({ value: city.name, label: city.name }))}
          selectedOption={city.value}
          className=''
          placeholder="Ciudad"
          errorMessage={submitted ? city.errorMessage() : null}
          onChange={(event) => { setCity(Selector.dirtyWithOptions(event.target.value, cities)) }}
        />

        <Input
          className=''
          placeholder="Dirección"
          value={address.value}
          errorMessage={submitted ? address.errorMessage() : null}
          onChange={(event) => { setAddress(Text.dirty(event.target.value)); }}
        />

        <OptionInput
          options={Constants.LAYERS.map((layer) => ({ value: layer, label: String(layer) }))}
          selectedOption={layer.value}
          className=''
          placeholder="Estrato"
          errorMessage={submitted ? layer.errorMessage() : null}
          onChange={(event) => { setLayer(Selector.dirtyWithOptions(event.target.value, Constants.LAYERS)) }}
        />

        <OptionInput
          options={Object.keys(Constants.CREDIT_YEARS_OF_LIVING_IN_RESIDENCY).map((key) => ({ value: Constants.CREDIT_YEARS_OF_LIVING_IN_RESIDENCY[key], label: key }))}
          selectedOption={yearsOfLiving.value}
          className=''
          placeholder="¿Cuanto tiempo llevas en la residencia?"
          errorMessage={submitted ? yearsOfLiving.errorMessage() : null}
          onChange={(event) => { setYearsOfLiving(Selector.dirtyWithOptions(event.target.value, Object.values(Constants.CREDIT_YEARS_OF_LIVING_IN_RESIDENCY))) }}
        />

        <OptionInput
          options={Object.keys(Constants.HOME_TENANTS).map((key) => ({ value: Constants.HOME_TENANTS[key], label: key }))}
          selectedOption={homeTenants.value}
          className=''
          placeholder="¿Con quien vives?"
          errorMessage={submitted ? homeTenants.errorMessage() : null}
          onChange={(event) => { setHomeTenants(Selector.dirtyWithOptions(event.target.value, Object.values(Constants.HOME_TENANTS))) }}
        />

        <OptionInput
          options={Constants.PEOPLE_IN_CHARGE.map((key) => ({ value: key, label: String(key) }))}
          selectedOption={peopleInCharge.value}
          className=''
          placeholder="Número de personas que dependen de ti"
          errorMessage={submitted ? peopleInCharge.errorMessage() : null}
          onChange={(event) => { setPeopleInCharge(Selector.dirtyWithOptions(event.target.value, Constants.PEOPLE_IN_CHARGE)) }}
        />

        <OptionInput
          options={Constants.NUMBER_OF_CHILDREN.map((key) => ({ value: key, label: String(key) }))}
          selectedOption={numberOfChildren.value}
          className=''
          placeholder="Número de hijos"
          errorMessage={submitted ? numberOfChildren.errorMessage() : null}
          onChange={(event) => { setNumberOfChildren(Selector.dirtyWithOptions(event.target.value, Constants.NUMBER_OF_CHILDREN)) }}
        />
      </div>

      <h2 className="font-bold text-xl mt-10">Datos residencia</h2>

      <div className="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full font-medium gap-5 mt-5">
        <OptionInput
          className=''
          placeholder="Ingresos mensuales"
          options={Object.keys(Constants.INCOME_RANGES).map((key) => ({ value: Constants.INCOME_RANGES[key], label: key }))}
          selectedOption={monthlyIncome.value}
          errorMessage={submitted ? monthlyIncome.errorMessage() : null}
          onChange={(event) => { setMonthlyIncome(Selector.dirtyWithOptions(event.target.value, Object.values(Constants.INCOME_RANGES))) }}
        />

        <OptionInput
          className=''
          placeholder="Ingresos mensuales del hogar"
          options={Object.keys(Constants.INCOME_RANGES).map((key) => ({ value: Constants.INCOME_RANGES[key], label: key }))}
          selectedOption={homeMonthlyIncome.value}
          errorMessage={submitted ? homeMonthlyIncome.errorMessage() : null}
          onChange={(event) => { setHomeMonthlyIncome(Selector.dirtyWithOptions(event.target.value, Object.values(Constants.INCOME_RANGES))) }}
        />

        <OptionInput
          options={Object.keys(Constants.EDUCATION_LEVELS).map((key) => ({ value: Constants.EDUCATION_LEVELS[key], label: key }))}
          selectedOption={educationLevel.value}
          className=''
          placeholder="Nivel educativo"
          errorMessage={submitted ? educationLevel.errorMessage() : null}
          onChange={(event) => { setEducationLevel(Selector.dirtyWithOptions(event.target.value, Object.values(Constants.EDUCATION_LEVELS))) }}
        />

        <OptionInput
          className=''
          placeholder="Tipo de vehículo"
          options={Object.keys(Constants.VEHICLE_TYPES).map((key) => ({ value: Constants.VEHICLE_TYPES[key], label: key }))}
          selectedOption={vehicleType.value}
          errorMessage={submitted ? vehicleType.errorMessage() : null}
          onChange={(event) => { setVehicleType(Selector.dirtyWithOptions(event.target.value, Object.values(Constants.VEHICLE_TYPES))) }}
        />

        <Input
          className=''
          placeholder="Placa (Si aplica)"
          value={vehiclePlate.value}
          errorMessage={submitted ? vehiclePlate.errorMessage() : null}
          onChange={(event) => { setVehiclePlate(Text.dirtyAllowEmpty(event.target.value)); }}
        />

        <OptionInput
          className=''
          placeholder="Ocupación"
          options={Object.keys(Constants.OCCUPATIONS).map((key) => ({ value: Constants.OCCUPATIONS[key], label: key }))}
          selectedOption={occupation.value}
          errorMessage={submitted ? occupation.errorMessage() : null}
          onChange={(event) => { setOccupation(Selector.dirtyWithOptions(event.target.value, Object.values(Constants.OCCUPATIONS))) }}
        />

        <Input
          className=''
          placeholder="Empresa donde trabaja"
          value={company.value}
          errorMessage={submitted ? company.errorMessage() : null}
          onChange={(event) => { setCompany(Text.dirtyAllowEmpty(event.target.value)); }}
        />

        <Input
          className=''
          type="number"
          placeholder="Teléfono empresa"
          value={companyPhone.value}
          errorMessage={submitted ? companyPhone.errorMessage() : null}
          onChange={(event) => { setCompanyPhone(Number.dirtyAllowEmpty(event.target.value)); }}
        />
      </div>

      {
        Environment.env === 'development' && (
          <button className="absolute right-20" type="button" onClick={() => {
            developmentAutoFill();
          }}>Autofill</button>
        )
      }

      <div className="flex justify-between w-full mt-16">
        <Button loading={false} disabled={false} className="w-auto" text="Anterior" onClick={onPrevious} />
        <Button loading={false} disabled={false} className="w-auto" text="Siguiente" type="submit" />
      </div>
    </form>
  )
}

export default NewLoanFinancialDataForm;