import { useState } from 'react';
import { Loan } from '../../domain/models';


const useLoanPaymentDialog = () => {
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [payingLoan, setPayingLoan] = useState<Loan | null>(null);

  return {
    showPaymentDialog, setShowPaymentDialog,
    payingLoan, setPayingLoan
  };
};

export default useLoanPaymentDialog;