import { LoanDeprecated } from "../../../../domain/models";
import { LoanStatus } from "../../../../domain/models/loan";
import { Environment } from "../../../../utils";
import { LoanEntityDeprecated } from "../entities";

export default class LoanMapperDeprecated {
  static toModel(entity: LoanEntityDeprecated): LoanDeprecated {
    return new LoanDeprecated({
      id: entity.id,
      createdAt: new Date(entity.created_at),
      status: this.statusCdToLoanStatus(entity.credit_status_cd),
      amountToPay: entity.amount_to_pay,
      amountApproved: entity.amount_approved,
      amountRequested: entity.amount_requested,
      paymentUrl: entity.payment_url ? `${Environment.backendUrl}/${entity.payment_url}` : undefined,
      consecutive: entity.consecutive,
      chargesData: {
        amount: entity.charges_data['amount'],
        iva: entity.charges_data['iva'],
        insurance: entity.charges_data['insurance'],
        technology: entity.charges_data['technology'],
        administration: entity.charges_data['administration'],
        interest: entity.charges_data['interest'],
        total: entity.charges_data['total']
      },
      estimatedPaymentDate: new Date(entity.estimated_payment_date),
      depositedAt: new Date(entity.deposited_at),
      receiptUrl: entity.receipt_url ? `${Environment.backendUrl}/${entity.receipt_url}` : undefined
    });
  }

  static statusCdToLoanStatus = (statusCd: number): LoanStatus => {
    switch (statusCd) {
      case 0:
        return LoanStatus.open;
      case 1:
        return LoanStatus.approved;
      case 3:
        return LoanStatus.deposited;
      case 4:
        return LoanStatus.paid;
      case 5:
        return LoanStatus.rejected;
      case 10:
        return LoanStatus.pending;
      case 11:
        return LoanStatus.canceled;
      default:
        throw new Error(`Unknown status code: ${statusCd}`);
    }
  }
}