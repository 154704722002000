interface IOnlinePaymentInfo {
  amountToPay: number;
  amountToPayBeforeIva: number;
  iva: number;
}

export default class OnlinePaymentInfo {
  amountToPay: number;
  amountToPayBeforeIva: number;
  iva: number;

  constructor({
    amountToPay, amountToPayBeforeIva, iva
  }: IOnlinePaymentInfo) {
    this.amountToPay = amountToPay;
    this.amountToPayBeforeIva = amountToPayBeforeIva;
    this.iva = iva;
  }
}