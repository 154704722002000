import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import React, { cloneElement } from 'react';

interface AccountOptionProps {
  onClick?: () => void;
  icon?: React.ReactElement;
  name: string;
}

const AccountOption: React.FC<AccountOptionProps> = ({ icon, name, onClick }) => {
  const iconWithStyle = icon ? cloneElement(icon, {
    style: { fontSize: '1.5rem' }, // Default style, can be overridden by icon prop
  }) : null;

  return (
    <button className="flex py-4 px-5 rounded-md text-sm justify-between items-center hover:bg-gray-100" onClick={onClick}>
      <div className="flex flex-row space-x-2 items-center">
        {iconWithStyle}
        <span className="font-medium">{name}</span>
      </div>
      <ArrowForwardIosRoundedIcon style={{ fontSize: '1rem' }} />
    </button>
  )
}

export default AccountOption;