import ReactGA from "react-ga4";

interface ILogEvent {
  event: string;
  category: string;
  label: string;
}

const useAnalyticEvents = () => {
  const logEvent = (event: ILogEvent) => {
    ReactGA.event({
      action: event.event,
      category: event.category,
      label: event.label,
    });
  }

  return {
    logEvent
  }
};

export default useAnalyticEvents;