interface StepperProps {
  steps: string[];
  currentStep: number;
  className?: string;
}

const Stepper: React.FC<StepperProps> = ({ steps, currentStep, className }) => {
  return (
    <ol className={`${className} flex flex-col sm:flex-row flex-wrap w-full bg-white border border-gray-200 rounded-lg shadow-sm items-start`}>
      {
        steps.map((step, index) => {
          return (
            <li key={index} className={`ml-5 flex flex-row space-x-3 items-center ${index > currentStep ? 'text-gray-400' : 'text-sky-600'} py-5`}>
              <span className={`flex items-center justify-center w-5 h-5 text-xs border ${index > currentStep ? 'border-gray-400' : 'border-violet-600'} rounded-full shrink-0`}>
                {index + 1}
              </span>
              <div className="sm:inline-flex text-sm">{step}</div>
              {
                index < steps.length - 1 &&
                <svg className="hidden sm:flex w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7 9 4-4-4-4M1 9l4-4-4-4" />
                </svg>
              }
            </li>
          )
        })
      }
    </ol>
  )
}

export default Stepper;