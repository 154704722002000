import { Bank } from "../../../../domain/models";
import { BankEntity } from "../entities";

export default class BankMapper {
  static toModel(entity: BankEntity): Bank {
    return new Bank({
      name: entity.name,
      code: entity.code
    })
  }
}