export default class Environment {
  static get termsUrl() {
    return `${process.env.REACT_APP_LANDING_URL}/terminos`;
  }

  static get env() {
    return process.env.REACT_APP_ENV;
  }

  static get backendUrl() {
    return process.env.REACT_APP_BACKEND_URL;
  }

  static get epaycoApiKey() {
    return process.env.REACT_APP_EPAYCO_API_KEY;
  }
}