import { NavLink } from 'react-router-dom';
import { LogoWithText } from '../assets/images';
import { useSignupForm } from '../hooks';
import { Button, Input } from './';
import { Email, InputValidator, Password, Text, MobilePhone } from '../../infrastructure/inputs';
import Routes from '../../config/Routes';
import { Environment } from '../../utils';

interface SignupFormProps {
  className?: string;
}

const LoginForm: React.FC<SignupFormProps> = ({ className = '' }) => {
  const {
    loading,
    name,
    email,
    phone,
    password,
    setName,
    setEmail,
    setPhone,
    setPassword,
    submitted,
    submitForm
  } = useSignupForm();

  return (
    <form className={`${className} flex flex-wrap flex-col bg-white px-10 rounded-lg shadow-lg py-10 sm:py-0 sm:justify-center items-center`} onSubmit={(event) => { event.preventDefault(); submitForm(); }}>
      <img className="h-auto w-40" src={LogoWithText} alt="login" />
      <h1 className="text-3xl font-bold mt-5">Crea tu cuenta</h1>

      <div className="flex flex-col flex-wrap space-y-2 w-full mt-5">
        <Input
          placeholder="Nombre"
          type="text"
          value={name.value}
          errorMessage={submitted ? name.errorMessage() : null}
          onChange={(event) => { setName(Text.dirty(event.target.value)) }}
        />
        <Input
          placeholder="Email"
          type="email"
          value={email.value}
          errorMessage={submitted ? email.errorMessage() : null}
          onChange={(event) => { setEmail(Email.dirty(event.target.value)) }}
        />
        <Input
          placeholder="Celular"
          type="number"
          value={phone.value}
          errorMessage={submitted ? phone.errorMessage() : null}
          onChange={(event) => { setPhone(MobilePhone.dirty(event.target.value)) }}
        />
        <Input
          placeholder="Contraseña"
          type="password"
          value={password.value}
          errorMessage={submitted ? password.errorMessage() : null}
          onChange={(event) => { setPassword(Password.dirty(event.target.value)) }}
        />
      </div>

      <Button loading={loading} disabled={InputValidator.anyEmpty([name, email, phone, password])} className="mt-8 w-full" text="Crear cuenta" type='submit' />
      
      <div className="flex flex-wrap justify-center w-full text-sm mt-3">
        Al registrarte aceptas los <a href={Environment.termsUrl} target='_blank' rel='noreferrer' className="ml-1 text-sky-500 hover:text-sky-700 font-bold">términos y condiciones</a>
      </div>

      <div className="flex justify-center w-full text-sm mt-3">
        ¿Ya tienes cuenta? <NavLink to={Routes.LOGIN_PATH} className="ml-2 text-sky-500 hover:text-sky-700 font-bold">Inicia sesión</NavLink>
      </div>
    </form>
  )
}

export default LoginForm;