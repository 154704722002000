import { useState } from "react";
import { Date, HomePhone, InputValidator, MobilePhone, Selector, Text } from "../../infrastructure/inputs";
import { Alert, Constants, Environment } from "../../utils";
import { RestUsersRepository } from "../../infrastructure/repositories";
import { UsersRepository } from "../../domain/repositories";
import Routes from "../../config/Routes";
import { useLocation, useNavigate } from "react-router-dom";
import { User } from "../../domain/models";
import { updatePersonalInformationPayload } from "../../domain/repositories/UsersRepository";
import useConfig from "./useConfig";

const useUpdatePersonalInformationForm = () => {
  const { updateUser, user } = useConfig();
  const [firstName, setFirstName] = useState(Text.dirty(user?.firstName ?? ""));
  const [middleName, setMiddleName] = useState(Text.dirtyAllowEmpty(user?.secondName ?? ""));
  const [firstLasName, setFirstLastName] = useState(Text.dirty(user?.firstSurname ?? ""));
  const [middleLastName, setMiddleLastName] = useState(Text.dirty(user?.secondSurname ?? ""));
  const [mobilePhone, setMobilePhone] = useState(MobilePhone.dirty(user?.mobilePhone ?? ""));
  const [phone, setPhone] = useState(HomePhone.dirtyAllowEmpty(user?.phone ?? ""));
  const [birthday, setBirthday] = useState(Date.dirty(user?.birthDate ?? ""));
  const [gender, setGender] = useState(Selector.dirtyWithOptions(user?.genderCd?.toString() ?? "", Object.values(Constants.GENDER_TYPES)));
  const [civilStatus, setCivilStatus] = useState(Selector.dirtyWithOptions(user?.maritalStatusCd?.toString() ?? "", Object.values(Constants.CIVIL_STATUS_TYPES)));
  const [documentType, setDocumentType] = useState(Selector.dirtyWithOptions(user?.documentTypeCd?.toString() ?? "", Object.values(Constants.DOCUMENT_TYPES)));
  const [documentNumber, setDocumentNumber] = useState(Text.dirty(user?.documentNumber ?? ""));
  const [documentExpeditionDate, setDocumentExpeditionDate] = useState(Date.dirty(user?.documentExpeditionDate ?? ""));
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const usersRepository: UsersRepository = RestUsersRepository.getInstance();

  const submitForm = async () => {
    setSubmitted(true);

    if (!validForm()) return false;

    setLoading(true);
    
    const [user, errorMessage]: [User?, string?] = await usersRepository.updatePersonalInformation(data());
    setLoading(false);
    
    if (errorMessage) {
      Alert.showError(errorMessage!);
    } else {
      Alert.showSuccess(Constants.UPDATED_PERSONAL_INFORMATION_SUCCESSFULLY);
      updateUser(user!);

      if (updateAttemptComesFromApplyingForNewLoan()) {
        navigate(Routes.NEW_LOAN_PATH, { replace: true });
      } else {
        navigate(Routes.ACCOUNT_PATH, { replace: true });
      }
    }
  };

  const updateAttemptComesFromApplyingForNewLoan = (): boolean => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('from') === 'new-loan';
  }

  const data = (): updatePersonalInformationPayload => {
    return {
      first_name: firstName.value,
      second_name: middleName.value,
      first_surname: firstLasName.value,
      second_surname: middleLastName.value,
      birth_date: birthday.getDate()!,
      mobile_phone: mobilePhone.value,
      phone: phone.value,
      gender_cd: gender.getNumber()!,
      marital_status_cd: civilStatus.getNumber()!,
      document_number: documentNumber.value,
      document_expedition_date: documentExpeditionDate.getDate()!,
      document_type_cd: documentType.getNumber()!
    }
  }

  const developmentAutoFill = () => {
    if (Environment.env !== 'development') return;

    setFirstName(Text.dirty('William'));
    setMiddleName(Text.dirty('Ricardo'));
    setFirstLastName(Text.dirty('Ravelo'));
    setMiddleLastName(Text.dirty('Mendez'));
    setMobilePhone(MobilePhone.dirty('3013016284'));
    setPhone(HomePhone.dirty('4059959'));
    setBirthday(Date.dirty('2024-01-01'));
    setGender(Selector.dirtyWithOptions(Constants.GENDER_TYPES['Masculino'].toString(), Object.values(Constants.GENDER_TYPES)));
    setCivilStatus(Selector.dirtyWithOptions(Constants.CIVIL_STATUS_TYPES['Soltero'].toString(), Object.values(Constants.CIVIL_STATUS_TYPES)));
    setDocumentType(Selector.dirtyWithOptions(Constants.DOCUMENT_TYPES['Cedula de ciudadania'].toString(), Object.values(Constants.DOCUMENT_TYPES)));
    setDocumentNumber(Text.dirty('1233890417'));
    setDocumentExpeditionDate(Date.dirty('2024-01-01'));
  }

  const validForm = (): boolean => {
    return InputValidator.valid([
      firstName,
      middleName,
      firstLasName,
      middleLastName,
      mobilePhone,
      phone,
      birthday,
      gender,
      civilStatus,
      documentType,
      documentNumber,
      documentExpeditionDate,
    ]);
  }

  return {
    submitted, submitForm,
    firstName, setFirstName,
    middleName, setMiddleName,
    firstLasName, setFirstLastName,
    middleLastName, setMiddleLastName,
    mobilePhone, setMobilePhone,
    phone, setPhone,
    birthday, setBirthday,
    gender, setGender,
    civilStatus, setCivilStatus,
    documentType, setDocumentType,
    documentNumber, setDocumentNumber,
    documentExpeditionDate, setDocumentExpeditionDate,
    data, developmentAutoFill,
    loading, updateAttemptComesFromApplyingForNewLoan
  }
};

export default useUpdatePersonalInformationForm;