import { useUpdatePersonalInformationForm } from "../hooks";
import { Date as DateInput, HomePhone, InputValidator, MobilePhone, Selector, Text } from "./../../infrastructure/inputs";
import { Constants, Environment } from "./../../utils";
import Button from "./shared/Button";
import Input from "./shared/Input";
import OptionInput from "./shared/OptionInput";


interface UpdatePersonalInformationFormProps {
  className?: string;
  handler: ReturnType<typeof useUpdatePersonalInformationForm>;
}

const UpdatePersonalInformationForm: React.FC<UpdatePersonalInformationFormProps> = ({ className = '', handler }) => {
  const {
    submitted, submitForm,
    firstName, setFirstName,
    middleName, setMiddleName,
    firstLasName, setFirstLastName,
    middleLastName, setMiddleLastName,
    mobilePhone, setMobilePhone,
    phone, setPhone,
    birthday, setBirthday,
    gender, setGender,
    civilStatus, setCivilStatus,
    documentType, setDocumentType,
    documentNumber, setDocumentNumber,
    documentExpeditionDate, setDocumentExpeditionDate,
    developmentAutoFill, loading
  } = handler;

  const submit = () => {
    submitForm();
  }

  return (
    <form className={`${className} flex flex-wrap flex-col px-20 pb-20 pt-5 sm:justify-center items-center space-y-5`}
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      {
        Environment.env === 'development' && (
          <div className="flex w-full justify-end">
            <button className="" type="button" onClick={() => {
              developmentAutoFill();
            }}>Autofill</button>
          </div>
        )
      }

      <div className="flex w-full justify-start">
        <h2 className="font-bold text-xl">Datos personales</h2>
      </div>

      <div className="flex grid grid-cols-1 sm:grid-cols-2 w-full font-medium gap-5 mt-5">
        <Input
          placeholder="Primer nombre"
          value={firstName.value}
          errorMessage={submitted ? firstName.errorMessage() : null}
          onChange={(event) => { setFirstName(Text.dirty(event.target.value)) }}
        />

        <Input
          placeholder="Segundo nombre"
          value={middleName.value}
          errorMessage={submitted ? middleName.errorMessage() : null}
          onChange={(event) => { setMiddleName(Text.dirtyAllowEmpty(event.target.value)) }}
        />

        <Input
          placeholder="Primer apellido"
          value={firstLasName.value}
          errorMessage={submitted ? firstLasName.errorMessage() : null}
          onChange={(event) => { setFirstLastName(Text.dirty(event.target.value)) }}
        />

        <Input
          placeholder="Segundo apellido"
          value={middleLastName.value}
          errorMessage={submitted ? middleLastName.errorMessage() : null}
          onChange={(event) => { setMiddleLastName(Text.dirty(event.target.value)) }}
        />
      </div>

      <div className="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full font-medium gap-5 mt-5">
        <Input
          placeholder="Celular"
          type="number"
          value={mobilePhone.value}
          errorMessage={submitted ? mobilePhone.errorMessage() : null}
          onChange={(event) => { setMobilePhone(MobilePhone.dirty(event.target.value)) }}
        />

        <Input
          placeholder="Teléfono fijo"
          type="number"
          value={phone.value}
          errorMessage={submitted ? phone.errorMessage() : null}
          onChange={(event) => { setPhone(HomePhone.dirtyAllowEmpty(event.target.value)) }}
        />

        <Input
          className={birthday.empty() ? 'text-slate-400' : ''}
          placeholder="Fecha de nacimiento"
          type="date"
          value={birthday.value}
          errorMessage={submitted ? birthday.errorMessage() : null}
          onChange={(event) => { setBirthday(DateInput.dirty(event.target.value)) }}
          maxDate={new Date()}
        />

        <OptionInput
          options={Object.keys(Constants.GENDER_TYPES).map((key) => ({ value: Constants.GENDER_TYPES[key], label: key }))}
          selectedOption={gender.value}
          className=''
          placeholder="Genero"
          errorMessage={submitted ? gender.errorMessage() : null}
          onChange={(event) => { setGender(Selector.dirtyWithOptions(event.target.value, Object.values(Constants.GENDER_TYPES))) }}
        />

        <OptionInput
          options={Object.keys(Constants.CIVIL_STATUS_TYPES).map((key) => ({ value: Constants.CIVIL_STATUS_TYPES[key], label: key }))}
          selectedOption={civilStatus.value}
          className=''
          placeholder="Estado civil"
          errorMessage={submitted ? civilStatus.errorMessage() : null}
          onChange={(event) => { setCivilStatus(Selector.dirtyWithOptions(event.target.value, Object.values(Constants.CIVIL_STATUS_TYPES))) }}
        />
      </div>

      <div className="flex w-full justify-start">
        <h2 className="font-bold text-xl mt-10">Documento de identificación</h2>
      </div>

      <div className="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full font-medium gap-5 mt-5">
        <OptionInput
          options={Object.keys(Constants.DOCUMENT_TYPES).map((key) => ({ value: Constants.DOCUMENT_TYPES[key], label: key }))}
          selectedOption={documentType.value}
          className=''
          placeholder="Tipo de documento"
          errorMessage={submitted ? documentType.errorMessage() : null}
          onChange={(event) => { setDocumentType(Selector.dirtyWithOptions(event.target.value, Object.values(Constants.DOCUMENT_TYPES))) }}
        />

        <Input
          className=''
          type="number"
          placeholder="Número de documento"
          value={documentNumber.value}
          errorMessage={submitted ? documentNumber.errorMessage() : null}
          onChange={(event) => { setDocumentNumber(Text.dirty(event.target.value)) }}
        />

        <Input
          className={documentExpeditionDate.empty() ? 'text-slate-400' : ''}
          type="date"
          placeholder="Fecha de expedición"
          value={documentExpeditionDate.value}
          errorMessage={submitted ? documentExpeditionDate.errorMessage() : null}
          onChange={(event) => { setDocumentExpeditionDate(DateInput.dirty(event.target.value)) }}
          maxDate={new Date()}
        />
      </div>

      <div className="flex justify-end w-full mt-16">
        <Button
          loading={loading} disabled={
            InputValidator.anyEmpty([
              firstName,
              firstLasName,
              middleLastName,
              mobilePhone,
              birthday,
              gender,
              civilStatus,
              documentType,
              documentNumber,
              documentExpeditionDate
            ])
          }
          className="w-auto" text="Actualizar"
          type="submit"
        />
      </div>
    </form>
  )
}

export default UpdatePersonalInformationForm;