export default class Cities {
  static COLOMBIA = [
    { "name": "Abejorral", "state": "Antioquia" },
    { "name": "Abrego", "state": "Norte de Santander" },
    { "name": "Abriaquí", "state": "Antioquia" },
    { "name": "Acacias", "state": "Meta" },
    { "name": "Acandí", "state": "Chocó" },
    { "name": "Acevedo", "state": "Huila" },
    { "name": "Achí", "state": "Bolívar" },
    { "name": "Agrado", "state": "Huila" },
    { "name": "Aguachica", "state": "Cesar" },
    { "name": "Aguada", "state": "Santander" },
    { "name": "Aguadas", "state": "Caldas" },
    { "name": "Agua de Dios", "state": "Cundinamarca" },
    { "name": "Aguazul", "state": "Casanare" },
    { "name": "Agustín Codazzi", "state": "Cesar" },
    { "name": "Aipe", "state": "Huila" },
    { "name": "Albán", "state": "Nariño" },
    { "name": "Albán", "state": "Cundinamarca" },
    { "name": "Albania", "state": "Caquetá" },
    { "name": "Albania", "state": "La Guajira" },
    { "name": "Albania", "state": "Santander" },
    { "name": "Alcalá", "state": "Valle del Cauca" },
    { "name": "Aldana", "state": "Nariño" },
    { "name": "Alejandría", "state": "Antioquia" },
    { "name": "Algarrobo", "state": "Magdalena" },
    { "name": "Algeciras", "state": "Huila" },
    { "name": "Almaguer", "state": "Cauca" },
    { "name": "Almeida", "state": "Boyacá" },
    { "name": "Alpujarra", "state": "Tolima" },
    { "name": "Altamira", "state": "Huila" },
    { "name": "Alto Baudo", "state": "Chocó" },
    { "name": "Altos del Rosario", "state": "Bolívar" },
    { "name": "Alvarado", "state": "Tolima" },
    { "name": "Amagá", "state": "Antioquia" },
    { "name": "Amalfi", "state": "Antioquia" },
    { "name": "Ambalema", "state": "Tolima" },
    { "name": "Anapoima", "state": "Cundinamarca" },
    { "name": "Ancuyá", "state": "Nariño" },
    { "name": "Andalucía", "state": "Valle del Cauca" },
    { "name": "Andes", "state": "Antioquia" },
    { "name": "Angelópolis", "state": "Antioquia" },
    { "name": "Angostura", "state": "Antioquia" },
    { "name": "Anolaima", "state": "Cundinamarca" },
    { "name": "Anorí", "state": "Antioquia" },
    { "name": "Anserma", "state": "Caldas" },
    { "name": "Ansermanuevo", "state": "Valle del Cauca" },
    { "name": "Anza", "state": "Antioquia" },
    { "name": "Anzoátegui", "state": "Tolima" },
    { "name": "Apartadó", "state": "Antioquia" },
    { "name": "Apía", "state": "Risaralda" },
    { "name": "Apulo", "state": "Cundinamarca" },
    { "name": "Aquitania", "state": "Boyacá" },
    { "name": "Aracataca", "state": "Magdalena" },
    { "name": "Aranzazu", "state": "Caldas" },
    { "name": "Aratoca", "state": "Santander" },
    { "name": "Arauca", "state": "Arauca" },
    { "name": "Arauquita", "state": "Arauca" },
    { "name": "Arbeláez", "state": "Cundinamarca" },
    { "name": "Arboleda", "state": "Nariño" },
    { "name": "Arboledas", "state": "Norte de Santander" },
    { "name": "Arboletes", "state": "Antioquia" },
    { "name": "Arcabuco", "state": "Boyacá" },
    { "name": "Arenal", "state": "Bolívar" },
    { "name": "Argelia", "state": "Antioquia" },
    { "name": "Argelia", "state": "Cauca" },
    { "name": "Argelia", "state": "Valle del Cauca" },
    { "name": "Ariguaní", "state": "Magdalena" },
    { "name": "Arjona", "state": "Bolívar" },
    { "name": "Armenia", "state": "Antioquia" },
    { "name": "Armenia", "state": "Quindío" },
    { "name": "Armero", "state": "Tolima" },
    { "name": "Arroyohondo", "state": "Bolívar" },
    { "name": "Astrea", "state": "Cesar" },
    { "name": "Ataco", "state": "Tolima" },
    { "name": "Atrato", "state": "Chocó" },
    { "name": "Ayapel", "state": "Córdoba" },
    { "name": "Bagadó", "state": "Chocó" },
    { "name": "Bahía Solano", "state": "Chocó" },
    { "name": "Bajo Baudó", "state": "Chocó" },
    { "name": "Balboa", "state": "Cauca" },
    { "name": "Balboa", "state": "Risaralda" },
    { "name": "Baranoa", "state": "Atlántico" },
    { "name": "Baraya", "state": "Huila" },
    { "name": "Barbacoas", "state": "Nariño" },
    { "name": "Barbosa", "state": "Antioquia" },
    { "name": "Barbosa", "state": "Santander" },
    { "name": "Barichara", "state": "Santander" },
    { "name": "Barrancabermeja", "state": "Santander" },
    { "name": "Barranca de Upía", "state": "Meta" },
    { "name": "Barrancas", "state": "La Guajira" },
    { "name": "Barranco de Loba", "state": "Bolívar" },
    { "name": "Barranco Minas", "state": "Guainía" },
    { "name": "Barranquilla", "state": "Atlántico" },
    { "name": "Becerril", "state": "Cesar" },
    { "name": "Belalcázar", "state": "Caldas" },
    { "name": "Belén", "state": "Nariño" },
    { "name": "Belén", "state": "Boyacá" },
    { "name": "Belén de Bajira", "state": "Chocó" },
    { "name": "Belén de Los Andaquies", "state": "Caquetá" },
    { "name": "Belén de Umbría", "state": "Risaralda" },
    { "name": "Bello", "state": "Antioquia" },
    { "name": "Belmira", "state": "Antioquia" },
    { "name": "Beltrán", "state": "Cundinamarca" },
    { "name": "Berbeo", "state": "Boyacá" },
    { "name": "Betania", "state": "Antioquia" },
    { "name": "Betéitiva", "state": "Boyacá" },
    { "name": "Betulia", "state": "Antioquia" },
    { "name": "Betulia", "state": "Santander" },
    { "name": "Bituima", "state": "Cundinamarca" },
    { "name": "Boavita", "state": "Boyacá" },
    { "name": "Bochalema", "state": "Norte de Santander" },
    { "name": "Bogotá", "state": "Bogotá D.C." },
    { "name": "Bojacá", "state": "Cundinamarca" },
    { "name": "Bojaya", "state": "Chocó" },
    { "name": "Bolívar", "state": "Cauca" },
    { "name": "Bolívar", "state": "Santander" },
    { "name": "Bolívar", "state": "Valle del Cauca" },
    { "name": "Bosconia", "state": "Cesar" },
    { "name": "Boyacá", "state": "Boyacá" },
    { "name": "Briceño", "state": "Antioquia" },
    { "name": "Briceño", "state": "Boyacá" },
    { "name": "Bucaramanga", "state": "Santander" },
    { "name": "Bucarasica", "state": "Norte de Santander" },
    { "name": "Buenaventura", "state": "Valle del Cauca" },
    { "name": "Buenavista", "state": "Córdoba" },
    { "name": "Buenavista", "state": "Quindío" },
    { "name": "Buenavista", "state": "Sucre" },
    { "name": "Buena Vista", "state": "Boyacá" },
    { "name": "Buenos Aires", "state": "Cauca" },
    { "name": "Buesaco", "state": "Nariño" },
    { "name": "Bugalagrande", "state": "Valle del Cauca" },
    { "name": "Buriticá", "state": "Antioquia" },
    { "name": "Busbanzá", "state": "Boyacá" },
    { "name": "Cabrera", "state": "Cundinamarca" },
    { "name": "Cabrera", "state": "Santander" },
    { "name": "Cabuyaro", "state": "Meta" },
    { "name": "Cacahual", "state": "Guainía" },
    { "name": "Cáceres", "state": "Antioquia" },
    { "name": "Cachipay", "state": "Cundinamarca" },
    { "name": "Cachirá", "state": "Norte de Santander" },
    { "name": "Cácota", "state": "Norte de Santander" },
    { "name": "Caicedo", "state": "Antioquia" },
    { "name": "Caicedonia", "state": "Valle del Cauca" },
    { "name": "Caimito", "state": "Sucre" },
    { "name": "Cajamarca", "state": "Tolima" },
    { "name": "Cajibío", "state": "Cauca" },
    { "name": "Cajicá", "state": "Cundinamarca" },
    { "name": "Calamar", "state": "Bolívar" },
    { "name": "Calamar", "state": "Guaviare" },
    { "name": "Calarcá", "state": "Quindío" },
    { "name": "Caldas", "state": "Antioquia" },
    { "name": "Caldas", "state": "Boyacá" },
    { "name": "Caldono", "state": "Cauca" },
    { "name": "Cali", "state": "Valle del Cauca" },
    { "name": "California", "state": "Santander" },
    { "name": "Calima", "state": "Valle del Cauca" },
    { "name": "Caloto", "state": "Cauca" },
    { "name": "Campamento", "state": "Antioquia" },
    { "name": "Campoalegre", "state": "Huila" },
    { "name": "Campo de La Cruz", "state": "Atlántico" },
    { "name": "Campohermoso", "state": "Boyacá" },
    { "name": "Canalete", "state": "Córdoba" },
    { "name": "Cañasgordas", "state": "Antioquia" },
    { "name": "Candelaria", "state": "Atlántico" },
    { "name": "Candelaria", "state": "Valle del Cauca" },
    { "name": "Cantagallo", "state": "Bolívar" },
    { "name": "Caparrapí", "state": "Cundinamarca" },
    { "name": "Capitanejo", "state": "Santander" },
    { "name": "Caqueza", "state": "Cundinamarca" },
    { "name": "Caracolí", "state": "Antioquia" },
    { "name": "Caramanta", "state": "Antioquia" },
    { "name": "Carcasí", "state": "Santander" },
    { "name": "Carepa", "state": "Antioquia" },
    { "name": "Carmen de Apicala", "state": "Tolima" },
    { "name": "Carmen de Carupa", "state": "Cundinamarca" },
    { "name": "Carmen del Darien", "state": "Chocó" },
    { "name": "Carolina", "state": "Antioquia" },
    { "name": "Cartagena", "state": "Bolívar" },
    { "name": "Cartagena del Chairá", "state": "Caquetá" },
    { "name": "Cartago", "state": "Valle del Cauca" },
    { "name": "Carurú", "state": "Vaupés" },
    { "name": "Casabianca", "state": "Tolima" },
    { "name": "Castilla la Nueva", "state": "Meta" },
    { "name": "Caucasia", "state": "Antioquia" },
    { "name": "Cepitá", "state": "Santander" },
    { "name": "Cereté", "state": "Córdoba" },
    { "name": "Cerinza", "state": "Boyacá" },
    { "name": "Cerrito", "state": "Santander" },
    { "name": "Cerro San Antonio", "state": "Magdalena" },
    { "name": "Cértegui", "state": "Chocó" },
    { "name": "Chachagüí", "state": "Nariño" },
    { "name": "Chaguaní", "state": "Cundinamarca" },
    { "name": "Chalán", "state": "Sucre" },
    { "name": "Chámeza", "state": "Casanare" },
    { "name": "Chaparral", "state": "Tolima" },
    { "name": "Charalá", "state": "Santander" },
    { "name": "Charta", "state": "Santander" },
    { "name": "Chía", "state": "Cundinamarca" },
    { "name": "Chigorodó", "state": "Antioquia" },
    { "name": "Chimá", "state": "Córdoba" },
    { "name": "Chimá", "state": "Santander" },
    { "name": "Chimichagua", "state": "Cesar" },
    { "name": "Chinácota", "state": "Norte de Santander" },
    { "name": "Chinavita", "state": "Boyacá" },
    { "name": "Chinchiná", "state": "Caldas" },
    { "name": "Chinú", "state": "Córdoba" },
    { "name": "Chipaque", "state": "Cundinamarca" },
    { "name": "Chipatá", "state": "Santander" },
    { "name": "Chiquinquirá", "state": "Boyacá" },
    { "name": "Chíquiza", "state": "Boyacá" },
    { "name": "Chiriguaná", "state": "Cesar" },
    { "name": "Chiscas", "state": "Boyacá" },
    { "name": "Chita", "state": "Boyacá" },
    { "name": "Chitagá", "state": "Norte de Santander" },
    { "name": "Chitaraque", "state": "Boyacá" },
    { "name": "Chivatá", "state": "Boyacá" },
    { "name": "Chivolo", "state": "Magdalena" },
    { "name": "Chivor", "state": "Boyacá" },
    { "name": "Choachí", "state": "Cundinamarca" },
    { "name": "Chocontá", "state": "Cundinamarca" },
    { "name": "Cicuco", "state": "Bolívar" },
    { "name": "Ciénaga", "state": "Magdalena" },
    { "name": "Ciénaga de Oro", "state": "Córdoba" },
    { "name": "Ciénega", "state": "Boyacá" },
    { "name": "Cimitarra", "state": "Santander" },
    { "name": "Circasia", "state": "Quindío" },
    { "name": "Cisneros", "state": "Antioquia" },
    { "name": "Ciudad Bolívar", "state": "Antioquia" },
    { "name": "Clemencia", "state": "Bolívar" },
    { "name": "Cocorná", "state": "Antioquia" },
    { "name": "Coello", "state": "Tolima" },
    { "name": "Cogua", "state": "Cundinamarca" },
    { "name": "Colombia", "state": "Huila" },
    { "name": "Colón", "state": "Nariño" },
    { "name": "Colón", "state": "Putumayo" },
    { "name": "Coloso", "state": "Sucre" },
    { "name": "Cómbita", "state": "Boyacá" },
    { "name": "Concepción", "state": "Antioquia" },
    { "name": "Concepción", "state": "Santander" },
    { "name": "Concordia", "state": "Antioquia" },
    { "name": "Concordia", "state": "Magdalena" },
    { "name": "Condoto", "state": "Chocó" },
    { "name": "Confines", "state": "Santander" },
    { "name": "Consaca", "state": "Nariño" },
    { "name": "Contadero", "state": "Nariño" },
    { "name": "Contratación", "state": "Santander" },
    { "name": "Convención", "state": "Norte de Santander" },
    { "name": "Copacabana", "state": "Antioquia" },
    { "name": "Coper", "state": "Boyacá" },
    { "name": "Córdoba", "state": "Bolívar" },
    { "name": "Córdoba", "state": "Nariño" },
    { "name": "Córdoba", "state": "Quindío" },
    { "name": "Corinto", "state": "Cauca" },
    { "name": "Coromoro", "state": "Santander" },
    { "name": "Corozal", "state": "Sucre" },
    { "name": "Corrales", "state": "Boyacá" },
    { "name": "Cota", "state": "Cundinamarca" },
    { "name": "Cotorra", "state": "Córdoba" },
    { "name": "Covarachía", "state": "Boyacá" },
    { "name": "Coveñas", "state": "Sucre" },
    { "name": "Coyaima", "state": "Tolima" },
    { "name": "Cravo Norte", "state": "Arauca" },
    { "name": "Cuaspud", "state": "Nariño" },
    { "name": "Cubará", "state": "Boyacá" },
    { "name": "Cubarral", "state": "Meta" },
    { "name": "Cucaita", "state": "Boyacá" },
    { "name": "Cucunubá", "state": "Cundinamarca" },
    { "name": "Cúcuta", "state": "Norte de Santander" },
    { "name": "Cucutilla", "state": "Norte de Santander" },
    { "name": "Cuítiva", "state": "Boyacá" },
    { "name": "Cumaral", "state": "Meta" },
    { "name": "Cumaribo", "state": "Vichada" },
    { "name": "Cumbal", "state": "Nariño" },
    { "name": "Cumbitara", "state": "Nariño" },
    { "name": "Cunday", "state": "Tolima" },
    { "name": "Curillo", "state": "Caquetá" },
    { "name": "Curití", "state": "Santander" },
    { "name": "Curumaní", "state": "Cesar" },
    { "name": "Dabeiba", "state": "Antioquia" },
    { "name": "Dagua", "state": "Valle del Cauca" },
    { "name": "Dibula", "state": "La Guajira" },
    { "name": "Distracción", "state": "La Guajira" },
    { "name": "Dolores", "state": "Tolima" },
    { "name": "Don Matías", "state": "Antioquia" },
    { "name": "Dosquebradas", "state": "Risaralda" },
    { "name": "Duitama", "state": "Boyacá" },
    { "name": "Durania", "state": "Norte de Santander" },
    { "name": "Ebéjico", "state": "Antioquia" },
    { "name": "El Águila", "state": "Valle del Cauca" },
    { "name": "El Bagre", "state": "Antioquia" },
    { "name": "El Banco", "state": "Magdalena" },
    { "name": "El Cairo", "state": "Valle del Cauca" },
    { "name": "El Calvario", "state": "Meta" },
    { "name": "El Cantón del San Pablo", "state": "Chocó" },
    { "name": "El Carmen", "state": "Norte de Santander" },
    { "name": "El Carmen de Atrato", "state": "Chocó" },
    { "name": "El Carmen de Bolívar", "state": "Bolívar" },
    { "name": "El Carmen de Chucurí", "state": "Santander" },
    { "name": "El Carmen de Viboral", "state": "Antioquia" },
    { "name": "El Castillo", "state": "Meta" },
    { "name": "El Cerrito", "state": "Valle del Cauca" },
    { "name": "El Charco", "state": "Nariño" },
    { "name": "El Cocuy", "state": "Boyacá" },
    { "name": "El Colegio", "state": "Cundinamarca" },
    { "name": "El Copey", "state": "Cesar" },
    { "name": "El Doncello", "state": "Caquetá" },
    { "name": "El Dorado", "state": "Meta" },
    { "name": "El Dovio", "state": "Valle del Cauca" },
    { "name": "El Encanto", "state": "Amazonas" },
    { "name": "El Espino", "state": "Boyacá" },
    { "name": "El Guacamayo", "state": "Santander" },
    { "name": "El Guamo", "state": "Bolívar" },
    { "name": "Elías", "state": "Huila" },
    { "name": "El Litoral del San Juan", "state": "Chocó" },
    { "name": "El Molino", "state": "La Guajira" },
    { "name": "El Paso", "state": "Cesar" },
    { "name": "El Paujil", "state": "Caquetá" },
    { "name": "El Peñol", "state": "Nariño" },
    { "name": "El Peñón", "state": "Bolívar" },
    { "name": "El Peñón", "state": "Cundinamarca" },
    { "name": "El Peñón", "state": "Santander" },
    { "name": "El Piñon", "state": "Magdalena" },
    { "name": "El Playón", "state": "Santander" },
    { "name": "El Retén", "state": "Magdalena" },
    { "name": "El Retorno", "state": "Guaviare" },
    { "name": "El Roble", "state": "Sucre" },
    { "name": "El Rosal", "state": "Cundinamarca" },
    { "name": "El Rosario", "state": "Nariño" },
    { "name": "El Santuario", "state": "Antioquia" },
    { "name": "El Tablón de Gómez", "state": "Nariño" },
    { "name": "El Tambo", "state": "Cauca" },
    { "name": "El Tambo", "state": "Nariño" },
    { "name": "El Tarra", "state": "Norte de Santander" },
    { "name": "El Zulia", "state": "Norte de Santander" },
    { "name": "Encino", "state": "Santander" },
    { "name": "Enciso", "state": "Santander" },
    { "name": "Entrerrios", "state": "Antioquia" },
    { "name": "Envigado", "state": "Antioquia" },
    { "name": "Espinal", "state": "Tolima" },
    { "name": "Facatativá", "state": "Cundinamarca" },
    { "name": "Falan", "state": "Tolima" },
    { "name": "Filadelfia", "state": "Caldas" },
    { "name": "Filandia", "state": "Quindío" },
    { "name": "Firavitoba", "state": "Boyacá" },
    { "name": "Flandes", "state": "Tolima" },
    { "name": "Florencia", "state": "Caquetá" },
    { "name": "Florencia", "state": "Cauca" },
    { "name": "Floresta", "state": "Boyacá" },
    { "name": "Florián", "state": "Santander" },
    { "name": "Florida", "state": "Valle del Cauca" },
    { "name": "Floridablanca", "state": "Santander" },
    { "name": "Fomeque", "state": "Cundinamarca" },
    { "name": "Fonseca", "state": "La Guajira" },
    { "name": "Fortul", "state": "Arauca" },
    { "name": "Fosca", "state": "Cundinamarca" },
    { "name": "Francisco Pizarro", "state": "Nariño" },
    { "name": "Fredonia", "state": "Antioquia" },
    { "name": "Fresno", "state": "Tolima" },
    { "name": "Frontino", "state": "Antioquia" },
    { "name": "Fuente de Oro", "state": "Meta" },
    { "name": "Fundación", "state": "Magdalena" },
    { "name": "Funes", "state": "Nariño" },
    { "name": "Funza", "state": "Cundinamarca" },
    { "name": "Fúquene", "state": "Cundinamarca" },
    { "name": "Fusagasugá", "state": "Cundinamarca" },
    { "name": "Gachala", "state": "Cundinamarca" },
    { "name": "Gachancipá", "state": "Cundinamarca" },
    { "name": "Gachantivá", "state": "Boyacá" },
    { "name": "Gachetá", "state": "Cundinamarca" },
    { "name": "Galán", "state": "Santander" },
    { "name": "Galapa", "state": "Atlántico" },
    { "name": "Galeras", "state": "Sucre" },
    { "name": "Gama", "state": "Cundinamarca" },
    { "name": "Gamarra", "state": "Cesar" },
    { "name": "Gambita", "state": "Santander" },
    { "name": "Gameza", "state": "Boyacá" },
    { "name": "Garagoa", "state": "Boyacá" },
    { "name": "Garzón", "state": "Huila" },
    { "name": "Génova", "state": "Quindío" },
    { "name": "Gigante", "state": "Huila" },
    { "name": "Ginebra", "state": "Valle del Cauca" },
    { "name": "Giraldo", "state": "Antioquia" },
    { "name": "Girardot", "state": "Cundinamarca" },
    { "name": "Girardota", "state": "Antioquia" },
    { "name": "Girón", "state": "Santander" },
    { "name": "Gómez Plata", "state": "Antioquia" },
    { "name": "González", "state": "Cesar" },
    { "name": "Gramalote", "state": "Norte de Santander" },
    { "name": "Granada", "state": "Cundinamarca" },
    { "name": "Granada", "state": "Meta" },
    { "name": "Granada", "state": "Antioquia" },
    { "name": "Guaca", "state": "Santander" },
    { "name": "Guacamayas", "state": "Boyacá" },
    { "name": "Guacarí", "state": "Valle del Cauca" },
    { "name": "Guachené", "state": "Cauca" },
    { "name": "Guachetá", "state": "Cundinamarca" },
    { "name": "Guachucal", "state": "Nariño" },
    { "name": "Guadalajara de Buga", "state": "Valle del Cauca" },
    { "name": "Guadalupe", "state": "Antioquia" },
    { "name": "Guadalupe", "state": "Huila" },
    { "name": "Guadalupe", "state": "Santander" },
    { "name": "Guaduas", "state": "Cundinamarca" },
    { "name": "Guaitarilla", "state": "Nariño" },
    { "name": "Gualmatán", "state": "Nariño" },
    { "name": "Guamal", "state": "Magdalena" },
    { "name": "Guamal", "state": "Meta" },
    { "name": "Guamo", "state": "Tolima" },
    { "name": "Guapi", "state": "Cauca" },
    { "name": "Guapotá", "state": "Santander" },
    { "name": "Guaranda", "state": "Sucre" },
    { "name": "Guarne", "state": "Antioquia" },
    { "name": "Guasca", "state": "Cundinamarca" },
    { "name": "Guatapé", "state": "Antioquia" },
    { "name": "Guataquí", "state": "Cundinamarca" },
    { "name": "Guatavita", "state": "Cundinamarca" },
    { "name": "Guateque", "state": "Boyacá" },
    { "name": "Guática", "state": "Risaralda" },
    { "name": "Guavatá", "state": "Santander" },
    { "name": "Guayabal de Siquima", "state": "Cundinamarca" },
    { "name": "Guayabetal", "state": "Cundinamarca" },
    { "name": "Guayatá", "state": "Boyacá" },
    { "name": "Güepsa", "state": "Santander" },
    { "name": "Güicán", "state": "Boyacá" },
    { "name": "Gutiérrez", "state": "Cundinamarca" },
    { "name": "Hacarí", "state": "Norte de Santander" },
    { "name": "Hatillo de Loba", "state": "Bolívar" },
    { "name": "Hato", "state": "Santander" },
    { "name": "Hato Corozal", "state": "Casanare" },
    { "name": "Hatonuevo", "state": "La Guajira" },
    { "name": "Heliconia", "state": "Antioquia" },
    { "name": "Herrán", "state": "Norte de Santander" },
    { "name": "Herveo", "state": "Tolima" },
    { "name": "Hispania", "state": "Antioquia" },
    { "name": "Hobo", "state": "Huila" },
    { "name": "Honda", "state": "Tolima" },
    { "name": "Ibagué", "state": "Tolima" },
    { "name": "Icononzo", "state": "Tolima" },
    { "name": "Iles", "state": "Nariño" },
    { "name": "Imués", "state": "Nariño" },
    { "name": "Inírida", "state": "Guainía" },
    { "name": "Inzá", "state": "Cauca" },
    { "name": "Ipiales", "state": "Nariño" },
    { "name": "Iquira", "state": "Huila" },
    { "name": "Isnos", "state": "Huila" },
    { "name": "Istmina", "state": "Chocó" },
    { "name": "Itagui", "state": "Antioquia" },
    { "name": "Ituango", "state": "Antioquia" },
    { "name": "Iza", "state": "Boyacá" },
    { "name": "Jambaló", "state": "Cauca" },
    { "name": "Jamundí", "state": "Valle del Cauca" },
    { "name": "Jardín", "state": "Antioquia" },
    { "name": "Jenesano", "state": "Boyacá" },
    { "name": "Jericó", "state": "Antioquia" },
    { "name": "Jericó", "state": "Boyacá" },
    { "name": "Jerusalén", "state": "Cundinamarca" },
    { "name": "Jesús María", "state": "Santander" },
    { "name": "Jordán", "state": "Santander" },
    { "name": "Juan de Acosta", "state": "Atlántico" },
    { "name": "Junín", "state": "Cundinamarca" },
    { "name": "Juradó", "state": "Chocó" },
    { "name": "La Apartada", "state": "Córdoba" },
    { "name": "La Argentina", "state": "Huila" },
    { "name": "Labateca", "state": "Norte de Santander" },
    { "name": "La Belleza", "state": "Santander" },
    { "name": "Labranzagrande", "state": "Boyacá" },
    { "name": "La Calera", "state": "Cundinamarca" },
    { "name": "La Capilla", "state": "Boyacá" },
    { "name": "La Ceja", "state": "Antioquia" },
    { "name": "La Celia", "state": "Risaralda" },
    { "name": "La Chorrera", "state": "Amazonas" },
    { "name": "La Cruz", "state": "Nariño" },
    { "name": "La Cumbre", "state": "Valle del Cauca" },
    { "name": "La Dorada", "state": "Caldas" },
    { "name": "La Esperanza", "state": "Norte de Santander" },
    { "name": "La Estrella", "state": "Antioquia" },
    { "name": "La Florida", "state": "Nariño" },
    { "name": "La Gloria", "state": "Cesar" },
    { "name": "La Guadalupe", "state": "Guainía" },
    { "name": "La Jagua de Ibirico", "state": "Cesar" },
    { "name": "La Jagua del Pilar", "state": "La Guajira" },
    { "name": "La Llanada", "state": "Nariño" },
    { "name": "La Macarena", "state": "Meta" },
    { "name": "La Merced", "state": "Caldas" },
    { "name": "La Mesa", "state": "Cundinamarca" },
    { "name": "La Montañita", "state": "Caquetá" },
    { "name": "Landázuri", "state": "Santander" },
    { "name": "La Palma", "state": "Cundinamarca" },
    { "name": "La Paz", "state": "Cesar" },
    { "name": "La Paz", "state": "Santander" },
    { "name": "La Pedrera", "state": "Amazonas" },
    { "name": "La Peña", "state": "Cundinamarca" },
    { "name": "La Pintada", "state": "Antioquia" },
    { "name": "La Plata", "state": "Huila" },
    { "name": "La Playa", "state": "Norte de Santander" },
    { "name": "La Primavera", "state": "Vichada" },
    { "name": "La Salina", "state": "Casanare" },
    { "name": "La Sierra", "state": "Cauca" },
    { "name": "La Tebaida", "state": "Quindío" },
    { "name": "La Tola", "state": "Nariño" },
    { "name": "La Unión", "state": "Antioquia" },
    { "name": "La Unión", "state": "Nariño" },
    { "name": "La Unión", "state": "Sucre" },
    { "name": "La Unión", "state": "Valle del Cauca" },
    { "name": "La Uvita", "state": "Boyacá" },
    { "name": "La Vega", "state": "Cauca" },
    { "name": "La Vega", "state": "Cundinamarca" },
    { "name": "La Victoria", "state": "Boyacá" },
    { "name": "La Victoria", "state": "Amazonas" },
    { "name": "La Victoria", "state": "Valle del Cauca" },
    { "name": "La Virginia", "state": "Risaralda" },
    { "name": "Lebríja", "state": "Santander" },
    { "name": "Leguízamo", "state": "Putumayo" },
    { "name": "Leiva", "state": "Nariño" },
    { "name": "Lejanías", "state": "Meta" },
    { "name": "Lenguazaque", "state": "Cundinamarca" },
    { "name": "Lérida", "state": "Tolima" },
    { "name": "Leticia", "state": "Amazonas" },
    { "name": "Líbano", "state": "Tolima" },
    { "name": "Liborina", "state": "Antioquia" },
    { "name": "Linares", "state": "Nariño" },
    { "name": "Lloró", "state": "Chocó" },
    { "name": "López", "state": "Cauca" },
    { "name": "Lorica", "state": "Córdoba" },
    { "name": "Los Andes", "state": "Nariño" },
    { "name": "Los Córdobas", "state": "Córdoba" },
    { "name": "Los Palmitos", "state": "Sucre" },
    { "name": "Los Patios", "state": "Norte de Santander" },
    { "name": "Los Santos", "state": "Santander" },
    { "name": "Lourdes", "state": "Norte de Santander" },
    { "name": "Luruaco", "state": "Atlántico" },
    { "name": "Macanal", "state": "Boyacá" },
    { "name": "Macaravita", "state": "Santander" },
    { "name": "Maceo", "state": "Antioquia" },
    { "name": "Macheta", "state": "Cundinamarca" },
    { "name": "Madrid", "state": "Cundinamarca" },
    { "name": "Magangué", "state": "Bolívar" },
    { "name": "Magüí", "state": "Nariño" },
    { "name": "Mahates", "state": "Bolívar" },
    { "name": "Maicao", "state": "La Guajira" },
    { "name": "Majagual", "state": "Sucre" },
    { "name": "Málaga", "state": "Santander" },
    { "name": "Malambo", "state": "Atlántico" },
    { "name": "Mallama", "state": "Nariño" },
    { "name": "Manatí", "state": "Atlántico" },
    { "name": "Manaure", "state": "Cesar" },
    { "name": "Manaure", "state": "La Guajira" },
    { "name": "Maní", "state": "Casanare" },
    { "name": "Manizales", "state": "Caldas" },
    { "name": "Manta", "state": "Cundinamarca" },
    { "name": "Manzanares", "state": "Caldas" },
    { "name": "Mapiripán", "state": "Meta" },
    { "name": "Mapiripana", "state": "Guainía" },
    { "name": "Margarita", "state": "Bolívar" },
    { "name": "María la Baja", "state": "Bolívar" },
    { "name": "Marinilla", "state": "Antioquia" },
    { "name": "Maripí", "state": "Boyacá" },
    { "name": "Mariquita", "state": "Tolima" },
    { "name": "Marmato", "state": "Caldas" },
    { "name": "Marquetalia", "state": "Caldas" },
    { "name": "Marsella", "state": "Risaralda" },
    { "name": "Marulanda", "state": "Caldas" },
    { "name": "Matanza", "state": "Santander" },
    { "name": "Medellín", "state": "Antioquia" },
    { "name": "Medina", "state": "Cundinamarca" },
    { "name": "Medio Atrato", "state": "Chocó" },
    { "name": "Medio Baudó", "state": "Chocó" },
    { "name": "Medio San Juan", "state": "Chocó" },
    { "name": "Melgar", "state": "Tolima" },
    { "name": "Mercaderes", "state": "Cauca" },
    { "name": "Mesetas", "state": "Meta" },
    { "name": "Milán", "state": "Caquetá" },
    { "name": "Miraflores", "state": "Boyacá" },
    { "name": "Miraflores", "state": "Guaviare" },
    { "name": "Miranda", "state": "Cauca" },
    { "name": "Miriti Paraná", "state": "Amazonas" },
    { "name": "Mistrató", "state": "Risaralda" },
    { "name": "Mitú", "state": "Vaupés" },
    { "name": "Mocoa", "state": "Putumayo" },
    { "name": "Mogotes", "state": "Santander" },
    { "name": "Molagavita", "state": "Santander" },
    { "name": "Momil", "state": "Córdoba" },
    { "name": "Mompós", "state": "Bolívar" },
    { "name": "Mongua", "state": "Boyacá" },
    { "name": "Monguí", "state": "Boyacá" },
    { "name": "Moniquirá", "state": "Boyacá" },
    { "name": "Moñitos", "state": "Córdoba" },
    { "name": "Montebello", "state": "Antioquia" },
    { "name": "Montecristo", "state": "Bolívar" },
    { "name": "Montelíbano", "state": "Córdoba" },
    { "name": "Montenegro", "state": "Quindío" },
    { "name": "Montería", "state": "Córdoba" },
    { "name": "Monterrey", "state": "Casanare" },
    { "name": "Morales", "state": "Bolívar" },
    { "name": "Morales", "state": "Cauca" },
    { "name": "Morelia", "state": "Caquetá" },
    { "name": "Morichal", "state": "Guainía" },
    { "name": "Morroa", "state": "Sucre" },
    { "name": "Mosquera", "state": "Cundinamarca" },
    { "name": "Mosquera", "state": "Nariño" },
    { "name": "Motavita", "state": "Boyacá" },
    { "name": "Murillo", "state": "Tolima" },
    { "name": "Murindó", "state": "Antioquia" },
    { "name": "Mutatá", "state": "Antioquia" },
    { "name": "Mutiscua", "state": "Norte de Santander" },
    { "name": "Muzo", "state": "Boyacá" },
    { "name": "Nariño", "state": "Antioquia" },
    { "name": "Nariño", "state": "Cundinamarca" },
    { "name": "Nariño", "state": "Nariño" },
    { "name": "Nátaga", "state": "Huila" },
    { "name": "Natagaima", "state": "Tolima" },
    { "name": "Nechí", "state": "Antioquia" },
    { "name": "Necoclí", "state": "Antioquia" },
    { "name": "Neira", "state": "Caldas" },
    { "name": "Neiva", "state": "Huila" },
    { "name": "Nemocón", "state": "Cundinamarca" },
    { "name": "Nilo", "state": "Cundinamarca" },
    { "name": "Nimaima", "state": "Cundinamarca" },
    { "name": "Nobsa", "state": "Boyacá" },
    { "name": "Nocaima", "state": "Cundinamarca" },
    { "name": "Norcasia", "state": "Caldas" },
    { "name": "Norosí", "state": "Bolívar" },
    { "name": "Nóvita", "state": "Chocó" },
    { "name": "Nueva Granada", "state": "Magdalena" },
    { "name": "Nuevo Colón", "state": "Boyacá" },
    { "name": "Nunchía", "state": "Casanare" },
    { "name": "Nuquí", "state": "Chocó" },
    { "name": "Obando", "state": "Valle del Cauca" },
    { "name": "Ocamonte", "state": "Santander" },
    { "name": "Ocaña", "state": "Norte de Santander" },
    { "name": "Oiba", "state": "Santander" },
    { "name": "Oicatá", "state": "Boyacá" },
    { "name": "Olaya", "state": "Antioquia" },
    { "name": "Olaya Herrera", "state": "Nariño" },
    { "name": "Onzaga", "state": "Santander" },
    { "name": "Oporapa", "state": "Huila" },
    { "name": "Orito", "state": "Putumayo" },
    { "name": "Orocué", "state": "Casanare" },
    { "name": "Ortega", "state": "Tolima" },
    { "name": "Ospina", "state": "Nariño" },
    { "name": "Otanche", "state": "Boyacá" },
    { "name": "Ovejas", "state": "Sucre" },
    { "name": "Pachavita", "state": "Boyacá" },
    { "name": "Pacho", "state": "Cundinamarca" },
    { "name": "Pacoa", "state": "Vaupés" },
    { "name": "Pácora", "state": "Caldas" },
    { "name": "Padilla", "state": "Cauca" },
    { "name": "Páez", "state": "Boyacá" },
    { "name": "Páez", "state": "Cauca" },
    { "name": "Paicol", "state": "Huila" },
    { "name": "Pailitas", "state": "Cesar" },
    { "name": "Paime", "state": "Cundinamarca" },
    { "name": "Paipa", "state": "Boyacá" },
    { "name": "Pajarito", "state": "Boyacá" },
    { "name": "Palermo", "state": "Huila" },
    { "name": "Palestina", "state": "Caldas" },
    { "name": "Palestina", "state": "Huila" },
    { "name": "Palmar", "state": "Santander" },
    { "name": "Palmar de Varela", "state": "Atlántico" },
    { "name": "Palmas del Socorro", "state": "Santander" },
    { "name": "Palmira", "state": "Valle del Cauca" },
    { "name": "Palmito", "state": "Sucre" },
    { "name": "Palocabildo", "state": "Tolima" },
    { "name": "Pamplona", "state": "Norte de Santander" },
    { "name": "Pamplonita", "state": "Norte de Santander" },
    { "name": "Pana Pana", "state": "Guainía" },
    { "name": "Pandi", "state": "Cundinamarca" },
    { "name": "Panqueba", "state": "Boyacá" },
    { "name": "Papunahua", "state": "Vaupés" },
    { "name": "Páramo", "state": "Santander" },
    { "name": "Paratebueno", "state": "Cundinamarca" },
    { "name": "Pasca", "state": "Cundinamarca" },
    { "name": "Pasto", "state": "Nariño" },
    { "name": "Patía", "state": "Cauca" },
    { "name": "Pauna", "state": "Boyacá" },
    { "name": "Paya", "state": "Boyacá" },
    { "name": "Paz de Ariporo", "state": "Casanare" },
    { "name": "Paz de Río", "state": "Boyacá" },
    { "name": "Pedraza", "state": "Magdalena" },
    { "name": "Pelaya", "state": "Cesar" },
    { "name": "Peñol", "state": "Antioquia" },
    { "name": "Pensilvania", "state": "Caldas" },
    { "name": "Peque", "state": "Antioquia" },
    { "name": "Pereira", "state": "Risaralda" },
    { "name": "Pesca", "state": "Boyacá" },
    { "name": "Piamonte", "state": "Cauca" },
    { "name": "Piedecuesta", "state": "Santander" },
    { "name": "Piedras", "state": "Tolima" },
    { "name": "Piendamó", "state": "Cauca" },
    { "name": "Pijao", "state": "Quindío" },
    { "name": "Pijiño del Carmen", "state": "Magdalena" },
    { "name": "Pinchote", "state": "Santander" },
    { "name": "Pinillos", "state": "Bolívar" },
    { "name": "Piojó", "state": "Atlántico" },
    { "name": "Pisba", "state": "Boyacá" },
    { "name": "Pital", "state": "Huila" },
    { "name": "Pitalito", "state": "Huila" },
    { "name": "Pivijay", "state": "Magdalena" },
    { "name": "Planadas", "state": "Tolima" },
    { "name": "Planeta Rica", "state": "Córdoba" },
    { "name": "Plato", "state": "Magdalena" },
    { "name": "Policarpa", "state": "Nariño" },
    { "name": "Polonuevo", "state": "Atlántico" },
    { "name": "Ponedera", "state": "Atlántico" },
    { "name": "Popayán", "state": "Cauca" },
    { "name": "Pore", "state": "Casanare" },
    { "name": "Potosí", "state": "Nariño" },
    { "name": "Pradera", "state": "Valle del Cauca" },
    { "name": "Prado", "state": "Tolima" },
    { "name": "Providencia", "state": "Nariño" },
    { "name": "Providencia", "state": "Archipiélago de San Andrés, Providencia y Santa Catalina" },
    { "name": "Pueblo Bello", "state": "Cesar" },
    { "name": "Pueblo Nuevo", "state": "Córdoba" },
    { "name": "Pueblo Rico", "state": "Risaralda" },
    { "name": "Pueblorrico", "state": "Antioquia" },
    { "name": "Pueblo Viejo", "state": "Magdalena" },
    { "name": "Puente Nacional", "state": "Santander" },
    { "name": "Puerres", "state": "Nariño" },
    { "name": "Puerto Alegría", "state": "Amazonas" },
    { "name": "Puerto Arica", "state": "Amazonas" },
    { "name": "Puerto Asís", "state": "Putumayo" },
    { "name": "Puerto Berrío", "state": "Antioquia" },
    { "name": "Puerto Boyacá", "state": "Boyacá" },
    { "name": "Puerto Caicedo", "state": "Putumayo" },
    { "name": "Puerto Carreño", "state": "Vichada" },
    { "name": "Puerto Colombia", "state": "Guainía" },
    { "name": "Puerto Colombia", "state": "Atlántico" },
    { "name": "Puerto Concordia", "state": "Meta" },
    { "name": "Puerto Escondido", "state": "Córdoba" },
    { "name": "Puerto Gaitán", "state": "Meta" },
    { "name": "Puerto Guzmán", "state": "Putumayo" },
    { "name": "Puerto Libertador", "state": "Córdoba" },
    { "name": "Puerto Lleras", "state": "Meta" },
    { "name": "Puerto López", "state": "Meta" },
    { "name": "Puerto Nare", "state": "Antioquia" },
    { "name": "Puerto Nariño", "state": "Amazonas" },
    { "name": "Puerto Parra", "state": "Santander" },
    { "name": "Puerto Rico", "state": "Caquetá" },
    { "name": "Puerto Rico", "state": "Meta" },
    { "name": "Puerto Rondón", "state": "Arauca" },
    { "name": "Puerto Salgar", "state": "Cundinamarca" },
    { "name": "Puerto Santander", "state": "Amazonas" },
    { "name": "Puerto Santander", "state": "Norte de Santander" },
    { "name": "Puerto Tejada", "state": "Cauca" },
    { "name": "Puerto Triunfo", "state": "Antioquia" },
    { "name": "Puerto Wilches", "state": "Santander" },
    { "name": "Pulí", "state": "Cundinamarca" },
    { "name": "Pupiales", "state": "Nariño" },
    { "name": "Puracé", "state": "Cauca" },
    { "name": "Purificación", "state": "Tolima" },
    { "name": "Purísima", "state": "Córdoba" },
    { "name": "Quebradanegra", "state": "Cundinamarca" },
    { "name": "Quetame", "state": "Cundinamarca" },
    { "name": "Quibdó", "state": "Chocó" },
    { "name": "Quimbaya", "state": "Quindío" },
    { "name": "Quinchía", "state": "Risaralda" },
    { "name": "Quípama", "state": "Boyacá" },
    { "name": "Quipile", "state": "Cundinamarca" },
    { "name": "Ragonvalia", "state": "Norte de Santander" },
    { "name": "Ramiriquí", "state": "Boyacá" },
    { "name": "Ráquira", "state": "Boyacá" },
    { "name": "Recetor", "state": "Casanare" },
    { "name": "Regidor", "state": "Bolívar" },
    { "name": "Remedios", "state": "Antioquia" },
    { "name": "Remolino", "state": "Magdalena" },
    { "name": "Repelón", "state": "Atlántico" },
    { "name": "Restrepo", "state": "Meta" },
    { "name": "Restrepo", "state": "Valle del Cauca" },
    { "name": "Retiro", "state": "Antioquia" },
    { "name": "Ricaurte", "state": "Cundinamarca" },
    { "name": "Ricaurte", "state": "Nariño" },
    { "name": "Rio Blanco", "state": "Tolima" },
    { "name": "Río de Oro", "state": "Cesar" },
    { "name": "Riofrío", "state": "Valle del Cauca" },
    { "name": "Riohacha", "state": "La Guajira" },
    { "name": "Río Iro", "state": "Chocó" },
    { "name": "Rionegro", "state": "Antioquia" },
    { "name": "Rionegro", "state": "Santander" },
    { "name": "Río Quito", "state": "Chocó" },
    { "name": "Riosucio", "state": "Caldas" },
    { "name": "Riosucio", "state": "Chocó" },
    { "name": "Río Viejo", "state": "Bolívar" },
    { "name": "Risaralda", "state": "Caldas" },
    { "name": "Rivera", "state": "Huila" },
    { "name": "Roberto Payán", "state": "Nariño" },
    { "name": "Roldanillo", "state": "Valle del Cauca" },
    { "name": "Roncesvalles", "state": "Tolima" },
    { "name": "Rondón", "state": "Boyacá" },
    { "name": "Rosas", "state": "Cauca" },
    { "name": "Rovira", "state": "Tolima" },
    { "name": "Sabana de Torres", "state": "Santander" },
    { "name": "Sabanagrande", "state": "Atlántico" },
    { "name": "Sabanalarga", "state": "Antioquia" },
    { "name": "Sabanalarga", "state": "Atlántico" },
    { "name": "Sabanalarga", "state": "Casanare" },
    { "name": "Sabanas de San Angel", "state": "Magdalena" },
    { "name": "Sabaneta", "state": "Antioquia" },
    { "name": "Saboyá", "state": "Boyacá" },
    { "name": "Sácama", "state": "Casanare" },
    { "name": "Sáchica", "state": "Boyacá" },
    { "name": "Sahagún", "state": "Córdoba" },
    { "name": "Saladoblanco", "state": "Huila" },
    { "name": "Salamina", "state": "Caldas" },
    { "name": "Salamina", "state": "Magdalena" },
    { "name": "Salazar", "state": "Norte de Santander" },
    { "name": "Saldaña", "state": "Tolima" },
    { "name": "Salento", "state": "Quindío" },
    { "name": "Salgar", "state": "Antioquia" },
    { "name": "Samacá", "state": "Boyacá" },
    { "name": "Samaná", "state": "Caldas" },
    { "name": "Samaniego", "state": "Nariño" },
    { "name": "Sampués", "state": "Sucre" },
    { "name": "San Agustín", "state": "Huila" },
    { "name": "San Alberto", "state": "Cesar" },
    { "name": "San Andrés", "state": "Santander" },
    { "name": "San Andrés", "state": "Archipiélago de San Andrés, Providencia y Santa Catalina" },
    { "name": "San Andrés de Cuerquía", "state": "Antioquia" },
    { "name": "San Andrés de Tumaco", "state": "Nariño" },
    { "name": "San Andrés Sotavento", "state": "Córdoba" },
    { "name": "San Antero", "state": "Córdoba" },
    { "name": "San Antonio", "state": "Tolima" },
    { "name": "San Antonio del Tequendama", "state": "Cundinamarca" },
    { "name": "San Benito", "state": "Santander" },
    { "name": "San Benito Abad", "state": "Sucre" },
    { "name": "San Bernardo", "state": "Cundinamarca" },
    { "name": "San Bernardo", "state": "Nariño" },
    { "name": "San Bernardo del Viento", "state": "Córdoba" },
    { "name": "San Calixto", "state": "Norte de Santander" },
    { "name": "San Carlos", "state": "Córdoba" },
    { "name": "San Carlos", "state": "Antioquia" },
    { "name": "San Carlos de Guaroa", "state": "Meta" },
    { "name": "San Cayetano", "state": "Cundinamarca" },
    { "name": "San Cayetano", "state": "Norte de Santander" },
    { "name": "San Cristóbal", "state": "Bolívar" },
    { "name": "San Diego", "state": "Cesar" },
    { "name": "Sandoná", "state": "Nariño" },
    { "name": "San Eduardo", "state": "Boyacá" },
    { "name": "San Estanislao", "state": "Bolívar" },
    { "name": "San Felipe", "state": "Guainía" },
    { "name": "San Fernando", "state": "Bolívar" },
    { "name": "San Francisco", "state": "Antioquia" },
    { "name": "San Francisco", "state": "Cundinamarca" },
    { "name": "San Francisco", "state": "Putumayo" },
    { "name": "San Gil", "state": "Santander" },
    { "name": "San Jacinto", "state": "Bolívar" },
    { "name": "San Jacinto del Cauca", "state": "Bolívar" },
    { "name": "San Jerónimo", "state": "Antioquia" },
    { "name": "San Joaquín", "state": "Santander" },
    { "name": "San José", "state": "Caldas" },
    { "name": "San José de La Montaña", "state": "Antioquia" },
    { "name": "San José del Fragua", "state": "Caquetá" },
    { "name": "San José del Guaviare", "state": "Guaviare" },
    { "name": "San José del Palmar", "state": "Chocó" },
    { "name": "San José de Miranda", "state": "Santander" },
    { "name": "San José de Pare", "state": "Boyacá" },
    { "name": "San José de Uré", "state": "Córdoba" },
    { "name": "San Juan de Arama", "state": "Meta" },
    { "name": "San Juan de Betulia", "state": "Sucre" },
    { "name": "San Juan del Cesar", "state": "La Guajira" },
    { "name": "San Juan de Río Seco", "state": "Cundinamarca" },
    { "name": "San Juan de Urabá", "state": "Antioquia" },
    { "name": "San Juanito", "state": "Meta" },
    { "name": "San Juan Nepomuceno", "state": "Bolívar" },
    { "name": "San Lorenzo", "state": "Nariño" },
    { "name": "San Luis", "state": "Antioquia" },
    { "name": "San Luis", "state": "Tolima" },
    { "name": "San Luis de Gaceno", "state": "Casanare" },
    { "name": "San Luis de Gaceno", "state": "Boyacá" },
    { "name": "San Luis de Sincé", "state": "Sucre" },
    { "name": "San Marcos", "state": "Sucre" },
    { "name": "San Martín", "state": "Cesar" },
    { "name": "San Martín", "state": "Meta" },
    { "name": "San Martín de Loba", "state": "Bolívar" },
    { "name": "San Mateo", "state": "Boyacá" },
    { "name": "San Miguel", "state": "Santander" },
    { "name": "San Miguel", "state": "Putumayo" },
    { "name": "San Miguel de Sema", "state": "Boyacá" },
    { "name": "San Onofre", "state": "Sucre" },
    { "name": "San Pablo", "state": "Nariño" },
    { "name": "San Pablo de Borbur", "state": "Boyacá" },
    { "name": "San Pablo de Borbur", "state": "Bolívar" },
    { "name": "San Pedro", "state": "Antioquia" },
    { "name": "San Pedro", "state": "Sucre" },
    { "name": "San Pedro", "state": "Valle del Cauca" },
    { "name": "San Pedro de Cartago", "state": "Nariño" },
    { "name": "San Pedro de Uraba", "state": "Antioquia" },
    { "name": "San Pelayo", "state": "Córdoba" },
    { "name": "San Rafael", "state": "Antioquia" },
    { "name": "San Roque", "state": "Antioquia" },
    { "name": "San Sebastián", "state": "Cauca" },
    { "name": "San Sebastián de Buenavista", "state": "Magdalena" },
    { "name": "Santa Ana", "state": "Magdalena" },
    { "name": "Santa Bárbara", "state": "Antioquia" },
    { "name": "Santa Bárbara", "state": "Nariño" },
    { "name": "Santa Bárbara", "state": "Santander" },
    { "name": "Santa Bárbara de Pinto", "state": "Magdalena" },
    { "name": "Santa Catalina", "state": "Bolívar" },
    { "name": "Santacruz", "state": "Nariño" },
    { "name": "Santafé de Antioquia", "state": "Antioquia" },
    { "name": "Santa Helena del Opón", "state": "Santander" },
    { "name": "Santa Isabel", "state": "Tolima" },
    { "name": "Santa Lucía", "state": "Atlántico" },
    { "name": "Santa María", "state": "Boyacá" },
    { "name": "Santa María", "state": "Huila" },
    { "name": "Santa Marta", "state": "Magdalena" },
    { "name": "Santana", "state": "Boyacá" },
    { "name": "Santander de Quilichao", "state": "Cauca" },
    { "name": "Santa Rosa", "state": "Bolívar" },
    { "name": "Santa Rosa", "state": "Cauca" },
    { "name": "Santa Rosa de Cabal", "state": "Risaralda" },
    { "name": "Santa Rosa del Sur", "state": "Bolívar" },
    { "name": "Santa Rosa de Osos", "state": "Antioquia" },
    { "name": "Santa Rosa de Viterbo", "state": "Boyacá" },
    { "name": "Santa Rosalía", "state": "Vichada" },
    { "name": "Santa Sofía", "state": "Boyacá" },
    { "name": "Santiago", "state": "Putumayo" },
    { "name": "Santiago", "state": "Norte de Santander" },
    { "name": "Santiago de Tolú", "state": "Sucre" },
    { "name": "Santo Domingo", "state": "Antioquia" },
    { "name": "Santo Tomás", "state": "Atlántico" },
    { "name": "Santuario", "state": "Risaralda" },
    { "name": "San Vicente", "state": "Antioquia" },
    { "name": "San Vicente de Chucurí", "state": "Santander" },
    { "name": "San Vicente del Caguán", "state": "Caquetá" },
    { "name": "San Zenón", "state": "Magdalena" },
    { "name": "Sapuyes", "state": "Nariño" },
    { "name": "Saravena", "state": "Arauca" },
    { "name": "Sardinata", "state": "Norte de Santander" },
    { "name": "Sasaima", "state": "Cundinamarca" },
    { "name": "Sativanorte", "state": "Boyacá" },
    { "name": "Sativasur", "state": "Boyacá" },
    { "name": "Segovia", "state": "Antioquia" },
    { "name": "Sesquilé", "state": "Cundinamarca" },
    { "name": "Sevilla", "state": "Valle del Cauca" },
    { "name": "Siachoque", "state": "Boyacá" },
    { "name": "Sibaté", "state": "Cundinamarca" },
    { "name": "Sibundoy", "state": "Putumayo" },
    { "name": "Silos", "state": "Norte de Santander" },
    { "name": "Silvania", "state": "Cundinamarca" },
    { "name": "Silvia", "state": "Cauca" },
    { "name": "Simacota", "state": "Santander" },
    { "name": "Simijaca", "state": "Cundinamarca" },
    { "name": "Simití", "state": "Bolívar" },
    { "name": "Sincelejo", "state": "Sucre" },
    { "name": "Sipí", "state": "Chocó" },
    { "name": "Sitionuevo", "state": "Magdalena" },
    { "name": "Soacha", "state": "Cundinamarca" },
    { "name": "Soatá", "state": "Boyacá" },
    { "name": "Socha", "state": "Boyacá" },
    { "name": "Socorro", "state": "Santander" },
    { "name": "Socotá", "state": "Boyacá" },
    { "name": "Sogamoso", "state": "Boyacá" },
    { "name": "Solano", "state": "Caquetá" },
    { "name": "Soledad", "state": "Atlántico" },
    { "name": "Solita", "state": "Caquetá" },
    { "name": "Somondoco", "state": "Boyacá" },
    { "name": "Sonsón", "state": "Antioquia" },
    { "name": "Sopetrán", "state": "Antioquia" },
    { "name": "Soplaviento", "state": "Bolívar" },
    { "name": "Sopó", "state": "Cundinamarca" },
    { "name": "Sora", "state": "Boyacá" },
    { "name": "Soracá", "state": "Boyacá" },
    { "name": "Sotaquirá", "state": "Boyacá" },
    { "name": "Sotara", "state": "Cauca" },
    { "name": "Suaita", "state": "Santander" },
    { "name": "Suan", "state": "Atlántico" },
    { "name": "Suárez", "state": "Cauca" },
    { "name": "Suárez", "state": "Tolima" },
    { "name": "Suaza", "state": "Huila" },
    { "name": "Subachoque", "state": "Cundinamarca" },
    { "name": "Sucre", "state": "Cauca" },
    { "name": "Sucre", "state": "Santander" },
    { "name": "Sucre", "state": "Sucre" },
    { "name": "Suesca", "state": "Cundinamarca" },
    { "name": "Supatá", "state": "Cundinamarca" },
    { "name": "Supía", "state": "Caldas" },
    { "name": "Suratá", "state": "Santander" },
    { "name": "Susa", "state": "Cundinamarca" },
    { "name": "Susacón", "state": "Boyacá" },
    { "name": "Sutamarchán", "state": "Boyacá" },
    { "name": "Sutatausa", "state": "Cundinamarca" },
    { "name": "Sutatenza", "state": "Boyacá" },
    { "name": "Tabio", "state": "Cundinamarca" },
    { "name": "Tadó", "state": "Chocó" },
    { "name": "Talaigua Nuevo", "state": "Bolívar" },
    { "name": "Tamalameque", "state": "Cesar" },
    { "name": "Támara", "state": "Casanare" },
    { "name": "Tame", "state": "Arauca" },
    { "name": "Támesis", "state": "Antioquia" },
    { "name": "Taminango", "state": "Nariño" },
    { "name": "Tangua", "state": "Nariño" },
    { "name": "Taraira", "state": "Vaupés" },
    { "name": "Tarapacá", "state": "Amazonas" },
    { "name": "Tarazá", "state": "Antioquia" },
    { "name": "Tarqui", "state": "Huila" },
    { "name": "Tarso", "state": "Antioquia" },
    { "name": "Tasco", "state": "Boyacá" },
    { "name": "Tauramena", "state": "Casanare" },
    { "name": "Tausa", "state": "Cundinamarca" },
    { "name": "Tello", "state": "Huila" },
    { "name": "Tena", "state": "Cundinamarca" },
    { "name": "Tenerife", "state": "Magdalena" },
    { "name": "Tenjo", "state": "Cundinamarca" },
    { "name": "Tenza", "state": "Boyacá" },
    { "name": "Teorama", "state": "Norte de Santander" },
    { "name": "Teruel", "state": "Huila" },
    { "name": "Tesalia", "state": "Huila" },
    { "name": "Tibacuy", "state": "Cundinamarca" },
    { "name": "Tibaná", "state": "Boyacá" },
    { "name": "Tibasosa", "state": "Boyacá" },
    { "name": "Tibirita", "state": "Cundinamarca" },
    { "name": "Tibú", "state": "Norte de Santander" },
    { "name": "Tierralta", "state": "Córdoba" },
    { "name": "Timaná", "state": "Huila" },
    { "name": "Timbío", "state": "Cauca" },
    { "name": "Timbiquí", "state": "Cauca" },
    { "name": "Tinjacá", "state": "Boyacá" },
    { "name": "Tipacoque", "state": "Boyacá" },
    { "name": "Tiquisio", "state": "Bolívar" },
    { "name": "Titiribí", "state": "Antioquia" },
    { "name": "Toca", "state": "Boyacá" },
    { "name": "Tocaima", "state": "Cundinamarca" },
    { "name": "Tocancipá", "state": "Cundinamarca" },
    { "name": "Togüí", "state": "Boyacá" },
    { "name": "Toledo", "state": "Antioquia" },
    { "name": "Toledo", "state": "Norte de Santander" },
    { "name": "Tolú Viejo", "state": "Sucre" },
    { "name": "Tona", "state": "Santander" },
    { "name": "Tópaga", "state": "Boyacá" },
    { "name": "Topaipí", "state": "Cundinamarca" },
    { "name": "Toribio", "state": "Cauca" },
    { "name": "Toro", "state": "Valle del Cauca" },
    { "name": "Tota", "state": "Boyacá" },
    { "name": "Totoró", "state": "Cauca" },
    { "name": "Trinidad", "state": "Casanare" },
    { "name": "Trujillo", "state": "Valle del Cauca" },
    { "name": "Tubará", "state": "Atlántico" },
    { "name": "Tuchín", "state": "Córdoba" },
    { "name": "Tuluá", "state": "Valle del Cauca" },
    { "name": "Tunja", "state": "Boyacá" },
    { "name": "Tununguá", "state": "Boyacá" },
    { "name": "Túquerres", "state": "Nariño" },
    { "name": "Turbaco", "state": "Bolívar" },
    { "name": "Turbaná", "state": "Bolívar" },
    { "name": "Turbo", "state": "Antioquia" },
    { "name": "Turmequé", "state": "Boyacá" },
    { "name": "Tuta", "state": "Boyacá" },
    { "name": "Tutazá", "state": "Boyacá" },
    { "name": "Ubalá", "state": "Cundinamarca" },
    { "name": "Ubaque", "state": "Cundinamarca" },
    { "name": "Ulloa", "state": "Valle del Cauca" },
    { "name": "Umbita", "state": "Boyacá" },
    { "name": "Une", "state": "Cundinamarca" },
    { "name": "Unguía", "state": "Chocó" },
    { "name": "Unión Panamericana", "state": "Chocó" },
    { "name": "Uramita", "state": "Antioquia" },
    { "name": "Uribe", "state": "Meta" },
    { "name": "Uribia", "state": "La Guajira" },
    { "name": "Urrao", "state": "Antioquia" },
    { "name": "Urumita", "state": "La Guajira" },
    { "name": "Usiacurí", "state": "Atlántico" },
    { "name": "Útica", "state": "Cundinamarca" },
    { "name": "Valdivia", "state": "Antioquia" },
    { "name": "Valencia", "state": "Córdoba" },
    { "name": "Valle de Guamez", "state": "Putumayo" },
    { "name": "Valle de San José", "state": "Santander" },
    { "name": "Valle de San Juan", "state": "Tolima" },
    { "name": "Valledupar", "state": "Cesar" },
    { "name": "Valparaíso", "state": "Antioquia" },
    { "name": "Valparaíso", "state": "Caquetá" },
    { "name": "Vegachí", "state": "Antioquia" },
    { "name": "Vélez", "state": "Santander" },
    { "name": "Venadillo", "state": "Tolima" },
    { "name": "Venecia", "state": "Antioquia" },
    { "name": "Venecia", "state": "Cundinamarca" },
    { "name": "Ventaquemada", "state": "Boyacá" },
    { "name": "Vergara", "state": "Cundinamarca" },
    { "name": "Versalles", "state": "Valle del Cauca" },
    { "name": "Vetas", "state": "Santander" },
    { "name": "Vianí", "state": "Cundinamarca" },
    { "name": "Victoria", "state": "Caldas" },
    { "name": "Vigía del Fuerte", "state": "Antioquia" },
    { "name": "Vijes", "state": "Valle del Cauca" },
    { "name": "Villa Caro", "state": "Norte de Santander" },
    { "name": "Villa de Leyva", "state": "Boyacá" },
    { "name": "Villa del Rosario", "state": "Norte de Santander" },
    { "name": "Villa de San Diego de Ubate", "state": "Cundinamarca" },
    { "name": "Villagarzón", "state": "Putumayo" },
    { "name": "Villagómez", "state": "Cundinamarca" },
    { "name": "Villahermosa", "state": "Tolima" },
    { "name": "Villamaría", "state": "Caldas" },
    { "name": "Villanueva", "state": "Bolívar" },
    { "name": "Villanueva", "state": "La Guajira" },
    { "name": "Villanueva", "state": "Santander" },
    { "name": "Villanueva", "state": "Casanare" },
    { "name": "Villapinzón", "state": "Cundinamarca" },
    { "name": "Villa Rica", "state": "Cauca" },
    { "name": "Villarrica", "state": "Tolima" },
    { "name": "Villavicencio", "state": "Meta" },
    { "name": "Villavieja", "state": "Huila" },
    { "name": "Villeta", "state": "Cundinamarca" },
    { "name": "Viotá", "state": "Cundinamarca" },
    { "name": "Viracachá", "state": "Boyacá" },
    { "name": "Vista Hermosa", "state": "Meta" },
    { "name": "Viterbo", "state": "Caldas" },
    { "name": "Yacopí", "state": "Cundinamarca" },
    { "name": "Yacuanquer", "state": "Nariño" },
    { "name": "Yaguará", "state": "Huila" },
    { "name": "Yalí", "state": "Antioquia" },
    { "name": "Yarumal", "state": "Antioquia" },
    { "name": "Yavaraté", "state": "Vaupés" },
    { "name": "Yolombó", "state": "Antioquia" },
    { "name": "Yondó", "state": "Antioquia" },
    { "name": "Yopal", "state": "Casanare" },
    { "name": "Yotoco", "state": "Valle del Cauca" },
    { "name": "Yumbo", "state": "Valle del Cauca" },
    { "name": "Zambrano", "state": "Bolívar" },
    { "name": "Zapatoca", "state": "Santander" },
    { "name": "Zapayán", "state": "Magdalena" },
    { "name": "Zaragoza", "state": "Antioquia" },
    { "name": "Zarzal", "state": "Valle del Cauca" },
    { "name": "Zetaquira", "state": "Boyacá" },
    { "name": "Zipacón", "state": "Cundinamarca" },
    { "name": "Zipaquirá", "state": "Cundinamarca" },
    { "name": "Zona Bananera", "state": "Magdalena" }
  ]

  static getColombiaStates() {
    const allStates: string[] = this.COLOMBIA.map(city => city.state);
    return Array.from(new Set(allStates));
  } 
}