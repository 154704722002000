import { PaymentTransaction } from "../../../../domain/models";
import { PaymentTransactionEntity } from "../entities";

export default class PaymentTransactionMapper {
  static toModel(entity: PaymentTransactionEntity): PaymentTransaction {
    return new PaymentTransaction({
      id: entity.id,
      createdAt: entity.created_at,
      succeeded: entity.succeeded,
      amount: entity.amount
    });
  }
}