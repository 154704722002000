import { useState } from "react";
import { InputValidator, Number, Selector, Text } from "../../../infrastructure/inputs";
import { Cities, Constants, Environment, RangesUtil } from "../../../utils";
import { useSelector } from "react-redux";
import { selectConfig } from "../../../infrastructure/redux/selectors";

const useNewLoanFinancialDataForm = () => {
  const config = useSelector(selectConfig);
  const termRange: string[] = RangesUtil.generateRangeAsString(config?.minCreditTerm ?? 0, config?.maxCreditTerm ?? 0, Constants.CREDIT_TERM_STEP);
  const amountRange: string[] = RangesUtil.generateRangeAsString(config?.minCreditAmount ?? 0, config?.maxCreditAmount ?? 0, Constants.CREDIT_AMOUNT_STEP);
  const states = Cities.getColombiaStates();
  const cities = Cities.COLOMBIA.map((city) => city.name);

  const [amountRequested, setAmountRequested] = useState(Selector.dirtyWithOptions("", amountRange));
  const [creditDays, setCreditDays] = useState(Selector.dirtyWithOptions("", termRange));
  const [creditPurpose, setCreditPurpose] = useState(Selector.dirtyWithOptions("", config?.purposes.map(purpose => purpose.name) ?? []));
  const [city, setCity] = useState(Selector.dirtyWithOptions("", cities));
  const [state, setState] = useState(Selector.dirtyWithOptions("", states));
  const [address, setAddress] = useState(Text.dirty(""));
  const [layer, setLayer] = useState(Selector.dirtyWithOptions("", Constants.LAYERS));
  const [yearsOfLiving, setYearsOfLiving] = useState(Selector.dirtyWithOptions("", Object.values(Constants.CREDIT_YEARS_OF_LIVING_IN_RESIDENCY)));
  const [homeTenants, setHomeTenants] = useState(Selector.dirtyWithOptions("", Object.values(Constants.HOME_TENANTS)));
  const [peopleInCharge, setPeopleInCharge] = useState(Selector.dirtyWithOptions("", Constants.PEOPLE_IN_CHARGE));
  const [numberOfChildren, setNumberOfChildren] = useState(Selector.dirtyWithOptions("", Constants.NUMBER_OF_CHILDREN));
  const [monthlyIncome, setMonthlyIncome] = useState(Selector.dirtyWithOptions("", Object.values(Constants.INCOME_RANGES)));;
  const [homeMonthlyIncome, setHomeMonthlyIncome] = useState(Selector.dirtyWithOptions("", Object.values(Constants.INCOME_RANGES)));;
  const [educationLevel, setEducationLevel] = useState(Selector.dirtyWithOptions("", Object.values(Constants.EDUCATION_LEVELS)));
  const [vehicleType, setVehicleType] = useState(Selector.dirtyWithOptions("", Object.values(Constants.VEHICLE_TYPES)));
  const [vehiclePlate, setVehiclePlate] = useState(Text.dirtyAllowEmpty(""));
  const [occupation, setOccupation] = useState(Selector.dirtyWithOptions("", Object.values(Constants.OCCUPATIONS)));
  const [company, setCompany] = useState(Text.dirtyAllowEmpty(""));
  const [companyPhone, setCompanyPhone] = useState(Number.dirtyAllowEmpty(""));

  const [submitted, setSubmitted] = useState(false);

  const submitForm = () => {
    setSubmitted(true);
    if (!validForm()) return false;

    return true;
  };

  const developmentAutoFill = () => {
    if (Environment.env !== 'development') return;

    setAmountRequested(Selector.dirtyWithOptions('150000', amountRange));
    setCreditDays(Selector.dirtyWithOptions('30', termRange));
    setCreditPurpose(Selector.dirtyWithOptions(config?.purposes[3].id ?? '', config?.purposes.map(purpose => purpose.id) ?? []));
    setCity(Selector.dirtyWithOptions('Bogotá', cities));
    setState(Selector.dirtyWithOptions('Bogotá D.C.', states));
    setLayer(Selector.dirtyWithOptions(String(5), Constants.LAYERS));
    setYearsOfLiving(Selector.dirtyWithOptions(Constants.CREDIT_YEARS_OF_LIVING_IN_RESIDENCY[Object.keys(Constants.CREDIT_YEARS_OF_LIVING_IN_RESIDENCY)[0]].toString(), Object.values(Constants.CREDIT_YEARS_OF_LIVING_IN_RESIDENCY)));
    setHomeTenants(Selector.dirtyWithOptions(Constants.HOME_TENANTS['Padres'].toString(), Object.values(Constants.HOME_TENANTS)));
    setPeopleInCharge(Selector.dirtyWithOptions(String(2), Constants.PEOPLE_IN_CHARGE));
    setNumberOfChildren(Selector.dirtyWithOptions(String(0), Constants.NUMBER_OF_CHILDREN));
    setMonthlyIncome(Selector.dirtyWithOptions(Constants.INCOME_RANGES[Object.keys(Constants.INCOME_RANGES)[0]].toString(), Object.values(Constants.INCOME_RANGES)));
    setHomeMonthlyIncome(Selector.dirtyWithOptions(Constants.INCOME_RANGES[Object.keys(Constants.INCOME_RANGES)[0]].toString(), Object.values(Constants.INCOME_RANGES)));
    setEducationLevel(Selector.dirtyWithOptions(Constants.EDUCATION_LEVELS['Postgrado'].toString(), Object.values(Constants.EDUCATION_LEVELS)));
    setVehicleType(Selector.dirtyWithOptions(Constants.VEHICLE_TYPES['Carro'].toString(), Object.values(Constants.VEHICLE_TYPES)));
    setVehiclePlate(Text.dirty('KXR086'));
    setOccupation(Selector.dirtyWithOptions(Constants.OCCUPATIONS['Independiente'].toString(), Object.values(Constants.OCCUPATIONS)));
    setCompany(Text.dirty('Test'));
    setCompanyPhone(Number.dirty('3013016284'));
    setAddress(Text.dirty('Calle 25B #70B-50'));
  }

  const validForm = (): boolean => {
    return InputValidator.valid([
      state,
      layer,
      yearsOfLiving,
      homeTenants,
      peopleInCharge,
      numberOfChildren,
      monthlyIncome,
      homeMonthlyIncome,
      educationLevel,
      vehicleType,
      vehiclePlate,
      occupation,
      company,
      companyPhone,
      amountRequested,
      creditDays,
      creditPurpose,
      city,
      address
    ]);
  }

  const data = () => {
    return {
      amountRequested: amountRequested.getNumber(),
      creditDays: creditDays.getNumber(),
      creditPurpose: creditPurpose.value,
      city: city.value,
      state: state.value,
      layer: layer.getNumber(),
      yearsOfLiving: yearsOfLiving.getNumber(),
      homeTenants: homeTenants.getNumber(),
      peopleInCharge: peopleInCharge.getNumber(),
      numberOfChildren: numberOfChildren.getNumber(),
      monthlyIncome: monthlyIncome.getNumber(),
      homeMonthlyIncome: homeMonthlyIncome.getNumber(),
      educationLevel: educationLevel.getNumber(),
      vehicleType: vehicleType.getNumber(),
      vehiclePlate: vehiclePlate.value,
      occupation: occupation.getNumber(),
      company: company.value,
      companyPhone: companyPhone.value,
      address: address.value
    }
  }

  return {
    state, setState,
    layer, setLayer,
    yearsOfLiving, setYearsOfLiving,
    homeTenants, setHomeTenants,
    peopleInCharge, setPeopleInCharge,
    numberOfChildren, setNumberOfChildren,
    monthlyIncome, setMonthlyIncome,
    homeMonthlyIncome, setHomeMonthlyIncome,
    educationLevel, setEducationLevel,
    vehicleType, setVehicleType,
    vehiclePlate, setVehiclePlate,
    occupation, setOccupation,
    company, setCompany,
    companyPhone, setCompanyPhone,
    city, setCity,
    creditPurpose, setCreditPurpose,
    creditDays, setCreditDays,
    amountRequested, setAmountRequested,
    submitted, submitForm,
    data, developmentAutoFill,
    config, termRange, amountRange,
    address, setAddress,
    states, cities
  }
};

export default useNewLoanFinancialDataForm;