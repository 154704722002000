import {
  createBrowserRouter
} from "react-router-dom";
import {
  LoginPage,
  HomePage,
  NotFoundPage,
  SignupPage,
  AccountPage,
  UpdatePasswordPage,
  ResetPasswordPage,
  NewLoanPage,
  LoanDetailPage,
  PaymentMethodsPage,
  UpdatePersonalInformationPage
} from "../presentation/pages";
import Routes from "./Routes";
import { PrivateRoute } from "../presentation/components";

export default createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute />,
    children: [
      { path: Routes.HOME_PATH, element: <HomePage /> },
      { path: Routes.ACCOUNT_PATH, element: <AccountPage /> },
      { path: Routes.UPDATE_PASSWORD_PATH, element: <UpdatePasswordPage /> },
      { path: Routes.UPDATE_PERSONAL_INFORMATION, element: <UpdatePersonalInformationPage /> },
      { path: Routes.NEW_LOAN_PATH, element: <NewLoanPage /> },
      { path: Routes.LOAN_DETAIL_PATH, element: <LoanDetailPage /> },
      { path: Routes.PAYMENT_METHODS_PATH, element: <PaymentMethodsPage /> },
    ],
  },
  { path: Routes.LOGIN_PATH, element: <LoginPage />, },
  { path: Routes.SIGNUP_PATH, element: <SignupPage />, },
  { path: Routes.RESET_PASSWORD_PATH, element: <ResetPasswordPage />, },
  { path: '*', element: <NotFoundPage />, },
]);
