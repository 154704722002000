import { OnlinePaymentInfo } from "../../../../domain/models";
import { OnlinePaymentInfoEntity } from "../entities";

export default class OnlinePaymentInfoMapper {
  static toModel(entity: OnlinePaymentInfoEntity): OnlinePaymentInfo {
    return new OnlinePaymentInfo({
      amountToPay: entity.amount_to_pay,
      amountToPayBeforeIva: entity.amount_to_pay_before_iva,
      iva: entity.iva,
    });
  }
}