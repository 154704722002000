interface ILoanApplicationRequest {
  amount: number;
  days: number;
  purposeId: string;
  address: string;
  city: string;
  state: string;
  occupationCd: number;
  layer: number;
  yearsInAddress: number;
  livesWithCd: number;
  peopleInCharge: number;
  children: number;
  monthlySalary: number;
  totalMonthlyIncome: number;
  educationLevelCd: number;
  vehicleTypeCd: number;
  vehiclePlates?: string;
  company?: string;
  companyPhone?: string;
  personalReferenceName: string;
  personalReferencePhone: string;
  personalReferenceEmail: string;
  personalReferenceRelation: string;
  familyReferenceName: string;
  familyReferencePhone: string;
  familyReferenceEmail: string;
  familyReferenceRelation: string;
}

export default class LoanApplicationRequest {
  amount: number;
  days: number;
  purposeId: string;
  address: string;
  city: string;
  state: string;
  occupationCd: number;
  layer: number;
  yearsInAddress: number;
  livesWithCd: number;
  peopleInCharge: number;
  children: number;
  monthlySalary: number;
  totalMonthlyIncome: number;
  educationLevelCd: number;
  vehicleTypeCd: number;
  vehiclePlates?: string;
  company?: string;
  companyPhone?: string;
  personalReferenceName: string;
  personalReferencePhone: string;
  personalReferenceEmail: string;
  personalReferenceRelation: string;
  familyReferenceName: string;
  familyReferencePhone: string;
  familyReferenceEmail: string;
  familyReferenceRelation: string;

  constructor({
    amount,
    days,
    purposeId,
    address,
    city,
    state,
    occupationCd,
    layer,
    yearsInAddress,
    livesWithCd,
    peopleInCharge,
    children,
    monthlySalary,
    totalMonthlyIncome,
    educationLevelCd,
    vehicleTypeCd,
    vehiclePlates,
    company,
    companyPhone,
    personalReferenceName,
    personalReferencePhone,
    personalReferenceEmail,
    personalReferenceRelation,
    familyReferenceName,
    familyReferencePhone,
    familyReferenceEmail,
    familyReferenceRelation
  }: ILoanApplicationRequest) {
    this.amount = amount;
    this.days = days;
    this.purposeId = purposeId;
    this.address = address;
    this.city = city;
    this.state = state;
    this.occupationCd = occupationCd;
    this.layer = layer;
    this.yearsInAddress = yearsInAddress;
    this.livesWithCd = livesWithCd;
    this.peopleInCharge = peopleInCharge;
    this.children = children;
    this.monthlySalary = monthlySalary;
    this.totalMonthlyIncome = totalMonthlyIncome;
    this.educationLevelCd = educationLevelCd;
    this.vehicleTypeCd = vehicleTypeCd;
    this.vehiclePlates = vehiclePlates;
    this.company = company;
    this.companyPhone = companyPhone;
    this.personalReferenceName = personalReferenceName;
    this.personalReferencePhone = personalReferencePhone;
    this.personalReferenceEmail = personalReferenceEmail;
    this.personalReferenceRelation = personalReferenceRelation;
    this.familyReferenceName = familyReferenceName;
    this.familyReferencePhone = familyReferencePhone;
    this.familyReferenceEmail = familyReferenceEmail;
    this.familyReferenceRelation = familyReferenceRelation;
  }
}