import { LoanApplicationRequest } from "../../../../domain/models";

type Json = {
  [key: string]: any;
}

export default class LoanApplicationRequestMapper {
  static toJson(loanApplication: LoanApplicationRequest): Json {
    return {
      "amount": loanApplication.amount,
      "days": loanApplication.days,
      "purpose_id": loanApplication.purposeId,
      "address": loanApplication.address,
      "city": loanApplication.city,
      "state": loanApplication.state,
      "occupation_cd": loanApplication.occupationCd,
      "layer": loanApplication.layer,
      "years_in_address": loanApplication.yearsInAddress,
      "lives_with_cd": loanApplication.livesWithCd,
      "people_in_charge": loanApplication.peopleInCharge,
      "children": loanApplication.children,
      "monthly_salary": loanApplication.monthlySalary,
      "total_monthly_income": loanApplication.totalMonthlyIncome,
      "education_level_cd": loanApplication.educationLevelCd,
      "vehicle_type_cd": loanApplication.vehicleTypeCd,
      "vehicle_plates": loanApplication.vehiclePlates,
      "company": loanApplication.company,
      "company_phone": loanApplication.companyPhone,
      "personal_reference_name": loanApplication.personalReferenceName,
      "personal_reference_phone": loanApplication.personalReferencePhone,
      "personal_reference_email": loanApplication.personalReferenceEmail,
      "personal_reference_relation": loanApplication.personalReferenceRelation,
      "family_reference_name": loanApplication.familyReferenceName,
      "family_reference_phone": loanApplication.familyReferencePhone,
      "family_reference_email": loanApplication.familyReferenceEmail,
      "family_reference_relation": loanApplication.familyReferenceRelation
    }
  }
}