import { useConfig } from "../../hooks";
import { Splash } from "../";
import { useEffect } from "react";

interface ConfigProps {
  children: React.ReactNode;
}

const Config: React.FC<ConfigProps> = ({ children }) => {
  const { user, loadConfig } = useConfig();

  useEffect(() => {
    if(!user) {
      loadConfig();
    }
  });

  if (!user) {
    return(<Splash />);
  } else {
    return(
      <>{children}</>
    );
  }
}

export default Config;